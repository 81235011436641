import dayjs from "dayjs";
import React, { useEffect } from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

interface DatePickerProps {
  onDateSelect: (startDate: Date, doctors?: any) => void;
  selectedDate: Date | undefined;
  // availableSlots: { startTime: Dayjs; endTime: Dayjs; doctors: any }[];
}
const DatePicker = ({ onDateSelect, selectedDate }: DatePickerProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const twoWeeksAhead = dayjs().add(14, "day").toDate();

  const handleDayChange = (selectedDay: Date) => {
    const updatedDate = dayjs(selectedDay)
      .set("millisecond", 0)
      .set("second", 0);

    onDateSelect(updatedDate.toDate(), false);
  };
  const isNotSunday = (date: Date) => {
    const day = dayjs(date).day();
    return day !== 0;
  };

  return (
    <div className="date-picker">
      <div className="date-time-block u-margin-top-small">
        <ReactDatePicker
          calendarClassName="prime-medical-date-picker"
          selected={
            selectedDate ? dayjs(selectedDate?.toString()).toDate() : undefined
          }
          minDate={new Date()}
          filterDate={isNotSunday}
          maxDate={twoWeeksAhead}
          inline
          disabledKeyboardNavigation
          onChange={(date: Date) => {
            handleDayChange(date);
          }}
          onSelect={(date: Date) => {
            if (dayjs(selectedDate).isSame(date)) {
              handleDayChange(date);
            }
          }}
        />
      </div>
    </div>
  );
};

export default DatePicker;

import { gql } from "@apollo/client";

export default gql`
  mutation createDoctorUnavailable($input: DoctorUnavailableInput!) {
    createDoctorUnavailable(input: $input) {
      id
      startDate
      endDate
    }
  }
`;

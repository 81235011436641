import React from "react";
import { GrDocument } from "react-icons/gr";
import { IDocument } from "../../../../types";

const Document = ({ fileName, fileDirectory, uploadDate }: IDocument) => {
  return (
    <div className="document">
      <a href={fileDirectory}>
        <GrDocument />
        {fileName}
      </a>
      {uploadDate}
    </div>
  );
};

export default Document;

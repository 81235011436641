import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode, useRef } from "react";
import { FaChevronCircleDown, FaChevronCircleRight } from "react-icons/fa";

interface ServiceCategoryProps {
  title: string;
  children: ReactNode;
  open: boolean;
  onClick: () => void;
}

const ServiceCategory = ({
  title,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children,
  open,
  onClick,
}: ServiceCategoryProps) => {
  const content = useRef<HTMLElement | null>(null);

  function toggleAccordion() {
    onClick();
  }

  return (
    <div className="service-category">
      <AnimatePresence initial={false}>
        <motion.button
          animate={{
            backgroundColor: open ? "#ef6b8a" : "#304770",
          }}
          transition={{ duration: 0.2 }}
          onClick={toggleAccordion}
          className="category-header"
        >
          <h2>{title}</h2>

          {open ? (
            <FaChevronCircleDown className="accordion-icon" />
          ) : (
            <FaChevronCircleRight className="accordion-icon" />
          )}
        </motion.button>
      </AnimatePresence>
      {open && <section ref={content}>{children}</section>}
    </div>
  );
};

export default ServiceCategory;

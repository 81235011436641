import { useQuery } from "@apollo/client";
import React from "react";
import fetchDoctorLocations from "../../../../queries/fetchDoctorLocations";
import StandardError from "../../../shared/StandardError";

import StandardRadioSelect from "../../../shared/StandardRadioSelect";

interface SelectDoctorLocationProps {
  onChange: (doctorLocationId: number) => void;
  selectedDoctorLocationId: number | undefined;
}

const SelectDoctorLocation = ({
  onChange,
  selectedDoctorLocationId,
}: SelectDoctorLocationProps) => {
  const { data, loading, error } = useQuery(fetchDoctorLocations);
  const handleSetDoctorLocation = (locationId: string) => {
    onChange(Number.parseInt(locationId, 10));
  };
  if (loading) {
    return <div>Loading</div>;
  }
  if (error) return <StandardError />;

  const possibleLocations = data.doctorLocations.map((doctorLocation: any) => {
    return {
      value: doctorLocation.location.id,
      prefix: doctorLocation.location.city,
      name: doctorLocation.location.description,
    };
  });

  return (
    <div className="select-doctor-location">
      <h1 className="u-margin-bottom-small">
        Please select the city closest to you
      </h1>
      <div className="doctor-location-options">
        <StandardRadioSelect
          options={possibleLocations}
          selected={selectedDoctorLocationId?.toString()}
          onChange={handleSetDoctorLocation}
        />
      </div>
    </div>
  );
};

export default SelectDoctorLocation;

import React, { useContext } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

import { slide as Menu } from "react-burger-menu";
import { AuthContext } from "../../Auth";

import firebase from "../../firebase";
import BookButton from "../shared/BookButton";
import RegisterButton from "../shared/RegisterButton";

const Header = () => {
  const user = useContext(AuthContext);
  const { path } = useRouteMatch();

  const handleSignOut = () => {
    firebase.auth().signOut();
  };

  const renderMobileHeader = () => {
    return (
      <div className="u-margin-bottom-medium u-only-mobile">
        <header className="header ">
          <Menu right>
            <NavLink to="/landing">Home</NavLink>
            <NavLink to="/services">Services</NavLink>
            {path !== "/booking" && <NavLink to="/booking">Book Now</NavLink>}
            <a
              href="https://medium.com/@PrimeMedicalSolutions"
              rel="noreferrer"
              target="_blank"
            >
              Blog
            </a>
            {user.currentUser ? (
              <div>
                <NavLink to="/" onClick={handleSignOut}>
                  Logout
                </NavLink>
              </div>
            ) : null}
          </Menu>
          <div>
            {path !== "/booking" && (
              <div className="book-btn-wrapper">
                <BookButton />
              </div>
            )}
          </div>
        </header>
      </div>
    );
  };
  const renderDesktopHeader = () => {
    return (
      <header className="header u-hidden-mobile">
        {/* {path !== "/booking" && (
          <div className="book-btn-wrapper">
            <BookButton />
          </div>
        )} */}

        {path !== "/register" && (
          <div className="register-btn-wrapper">
            <RegisterButton />
          </div>
        )}

        <div className="tab-block">
          <NavLink
            to="/landing"
            className="tab-link"
            activeClassName="active-tab"
          >
            Home
          </NavLink>
          <NavLink
            to="/services"
            className="tab-link"
            activeClassName="active-tab"
          >
            Services
          </NavLink>

          <NavLink
            to={path !== "/booking" ? "/booking" : "#"}
            className="tab-link"
            activeClassName="active-tab"
          >
            Book Now
          </NavLink>
          <a
            href="https://medium.com/@PrimeMedicalSolutions"
            className="tab-link"
            rel="noreferrer"
            target="_blank"
          >
            Blog
          </a>
          {user.currentUser ? (
            <div>
              <NavLink to="/" className="tab-link" onClick={handleSignOut}>
                Logout
              </NavLink>
            </div>
          ) : null}
        </div>
      </header>
    );
  };

  return (
    <>
      {renderMobileHeader()}

      {renderDesktopHeader()}
    </>
  );
};

export default Header;

import React, { useState } from "react";
import { BiCalendarEvent, BiLogOut } from "react-icons/bi";
import { AiOutlineMenu } from "react-icons/ai";
import { RiHospitalLine, RiStethoscopeLine, RiTodoLine } from "react-icons/ri";
import { RiInformationLine } from "react-icons/ri";
import { NavLink, useRouteMatch } from "react-router-dom";
import {
  APPOINTMENTS,
  MANAGEPRACTICE,
  MANAGE_APPOINTMENT_TYPES,
  PATIENTS,
  PENDINGAPPS,
  PRACTICEINFORMATION,
} from "../../../constants";
import firebase from "../../../firebase";


const Sidebar = () => {
  const [visible, setVisible] = useState(true);
  const { url } = useRouteMatch();
  const handleSignOut = () => {
    firebase.auth().signOut();
  };
  const handleToggleVisibility = () => {
    setVisible(!visible);
  };
  if (!visible) {
    return (
      <div className="sidebar collapsed">
        <button
          className="collapse-button expand"
          type="button"
          onClick={handleToggleVisibility}
        >
          <AiOutlineMenu />
        </button>
      </div>
    );
  }
  return (
    <div className="sidebar">
      <div className="dashboard-heading">
        <button
          className="collapse-button"
          type="button"
          onClick={handleToggleVisibility}
        >
          <AiOutlineMenu />
        </button>
        <h2>Dashboard</h2>
      </div>

      <NavLink
        className="sidebar-item"
        activeClassName="active"
        to={`${url}/${APPOINTMENTS}`}
      >
        <BiCalendarEvent />
        Appointments
      </NavLink>

      <NavLink
        className="sidebar-item"
        activeClassName="active"
        to={`${url}/${PATIENTS}`}
      >
        <RiStethoscopeLine /> Patients
      </NavLink>
      <NavLink
        className="sidebar-item"
        activeClassName="active"
        to={`${url}/${MANAGE_APPOINTMENT_TYPES}`}
      >
        <BiCalendarEvent />
        Manage Types
      </NavLink>
      

      <NavLink
        className="sidebar-item"
        activeClassName="active"
        to={`${url}/${PRACTICEINFORMATION}`}
      >
        <RiInformationLine/>
       Practice Information
      
      
       </NavLink>
       <NavLink
       className="sidebar-item"
       activeClassName="active"
       to={`${url}/${MANAGEPRACTICE}`}>
         <RiHospitalLine/>
         Manage Practice
        
         </NavLink>

         <NavLink
       className="sidebar-item"
       activeClassName="active"
       to={`${url}/${PENDINGAPPS}`}>
         <RiTodoLine/>
        Pending Application
        
         </NavLink>


      <button onClick={handleSignOut} type="button" className="logout-button">
        <BiLogOut /> Logout
      </button>
    </div>
  );
};

export default Sidebar;

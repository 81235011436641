import React from "react";
import Layout from "../components/layout/Layout";
import Accordion from "../components/shared/Accordion";
import BookButton from "../components/shared/BookButton";
import CallOutNotice from "../components/shared/CallOutNotice";

const Services = () => {
  return (
    <Layout>
      <div className="services">
        <h1 className="services-header u-margin-top-medium u-margin-bottom-medium">
          Services
        </h1>
        <Accordion title="General Consultation">
          <div>
            <h3>General</h3>
            <div>At consultation room - R600</div>
            <div>Via video/telephone - R400</div>
          </div>
          <BookButton text="Book Appointment" />
          <CallOutNotice />
        </Accordion>

        <Accordion title="Covid-19">
          <div>
            <h3>Covid-19 consultation - R600</h3>
          </div>
          <div>
            <h3>Covid-19 lab test - R500</h3>
          </div>
          <BookButton text="Book Appointment" />
          <CallOutNotice />
        </Accordion>
        <Accordion title="IV Treatment">
          <div>
            <h3>Skin & hair glow - R1300</h3>
          </div>
          <div>
            <h3>Jetfuel - R1575</h3>
          </div>
          <div>
            <h3>Energy boost - R800</h3>
          </div>
          <div>
            <h3>Sports refuel - R1285</h3>
          </div>
          <div>
            <h3>Detox - R750</h3>
          </div>
          <div>
            <h3>Immune boost - R850</h3>
          </div>
          <div>
            <h3>Anti-Inflammatory - R1465</h3>
          </div>
          <BookButton text="Book Appointment" />
          <CallOutNotice />
        </Accordion>
        <Accordion title="Vaccinations">
          <div>
            <h3>Avaxim 80 - R309</h3>
          </div>
          <div>
            <h3>Boostrix Tetra - R550</h3>
          </div>
          <div>
            <h3>Cervavix - R1184</h3>
          </div>
          <div>
            <h3>Gardasil - R1160</h3>
          </div>
          <div>
            <h3>Infanrix Hexa - R600</h3>
          </div>
          <div>
            <h3>Menactra - R950</h3>
          </div>
          <div>
            <h3>Prevanar-13 - R1197</h3>
          </div>
          <div>
            <h3>Priorix - R300</h3>
          </div>
          <div>
            <h3>Rotarix - R600</h3>
          </div>
          <div>
            <h3>Tetavax - R119</h3>
          </div>
          <div>
            <h3>Varilrix - R555</h3>
          </div>

          <BookButton text="Book Appointment" />
          <CallOutNotice />
        </Accordion>
      </div>
    </Layout>
  );
};

export default Services;

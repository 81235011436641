import { gql } from "@apollo/client";

export default gql`
  query fetchDoctorsWithAppoinmentType {
    doctors {
      id
      firstName
      lastName
      emailAddress
      mobileNumber
      doctorAppointmentTypes {
        appointmentType {
          id
        }
      }
    }
    appointmentTypes {
      id
      description
    }
  }
`;

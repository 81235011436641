import { gql } from "@apollo/client";

export default gql`
  query fetchPatient($id: Int!) {
    patient(id: $id) {
      id
      firstName
      lastName
      emailAddress
      mobileNumber
    }
  }
`;

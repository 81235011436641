/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
// JSX used for filter and table as the types are incredibly complicated
import React, { useMemo } from "react";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useFilters, useSortBy, useTable } from "react-table";
import DefaultColumnFilter from "./DefaultColumnFilter";

import Filter from "./Filter";

const generateSortingIndicator = (column) => {
  if (column.isSorted) {
    return column.isSortedDesc ? (
      <BiSortDown style={{ verticalAlign: "middle" }} size={16} />
    ) : (
      <BiSortUp style={{ verticalAlign: "middle" }} size={16} />
    );
  }
  return "";
};

const PatientTable = ({ data }) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const columns = useMemo(
    () => [
      {
        Header: "First name",
        accessor: "firstName",
      },
      {
        Header: "Last name",
        accessor: "lastName",
      },
      { Header: "Email", accessor: "emailAddress" },
      { Header: "Phone number", accessor: "mobileNumber" },
      {
        Header: "View",
        Cell: ({ row: { original } }) => {
          return (
            <Link to={`/admin/patients/${original.id}`}>
              <FiArrowRight />
            </Link>
          );
        },
      },
    ],
    [],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data, defaultColumn }, useFilters, useSortBy);

  return (
    <table {...getTableProps()} style={{ borderSpacing: "0px" }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "1rem",
                  background: "#ef6b8a",
                  alignContent: "center",
                }}
              >
                <div>
                  {column.render("Header")}
                  {generateSortingIndicator(column)}
                </div>
                <Filter column={column} />
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "1rem",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PatientTable;

import React from "react";
import { FaExclamationCircle } from "react-icons/fa";

const StandardError = () => {
  return (
    <div className="u-text-center">
      <FaExclamationCircle size="2rem" />
      <p className="u-margin-top-small">
        An error has has occured. Please refresh and try again.
      </p>
    </div>
  );
};

export default StandardError;

import React, { useState } from "react";
import Accordion from "../../shared/Accordion";

function ManagePractice(){
const [selected, setSelected] = useState("");
const changeHandler = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setSelected(e.target.value);
  };
    return (  
        
      <div style={{height:"75vh"}} className="Register">
      {/* <div className="progressbar">
        <div style={{width: page === 0 ? "20%" : page === 1 ? "40%" :  page === 2 ? "60%" :page === 3 ? "80%" :"100%"}}></div>
      </div> */}
             <div className="header2">
          <br></br>
          <label style = {{fontSize:"40px"}}><strong></strong></label>
        </div>
      <div style={{width:"1300px"}} className="form-container">
      <Accordion title="Consultation types & Prices">
        <div><label style={{marginRight:"20px",marginLeft:"42px",width:"",}}><strong>Type</strong></label><label style={{marginRight:"20px",marginLeft:"192px",width:"",}}><strong>Price</strong></label><label style= {{marginLeft:"293px"}}><strong>Call-Out fee</strong></label><label style= {{marginLeft:"293px"}}><strong>Fee-amount</strong></label></div>
        <br></br>
      <div>
      <input style={{marginRight:"20px",marginLeft:"",width:"",}} type="checkbox" name="Name" /> 
      <label>
      Home
      
      <input style={{marginRight:"197px",marginLeft:"210px",width:"",}} type="text" name="Name" />
      </label>
      <select style={{width:"150px",height:"px"}}>
      <option value="CallOutFee">Call out fee</option>
      <option value="PerKm">Per/Km</option>
      </select>
      <input style={{marginRight:"0px",marginLeft:"256px",width:"",}} type="text" name="Name" />
      </div>

      <div>
      <input style={{marginRight:"20px",marginLeft:"",width:"",}} type="checkbox" name="Name" /> 
      <label>
      Virtual
      
      
      <input style={{marginRight:"20px",marginLeft:"207px",width:"",}} type="text" name="Name" />
      </label>
      </div>

      <div>
      <input style={{marginRight:"20px",marginLeft:"",width:"",}} type="checkbox" name="Name" /> 
      <label>
      Consultation Room
      
      
      <input style={{marginRight:"20px",marginLeft:"91px",width:"",}} type="text" name="Name" />
      </label>
      </div>
      <br></br>
      <div style = {{marginLeft:"1083px",}}><button style={{height:"40px",width:"172px"}}>Submit</button></div>
      </Accordion>

      <Accordion title="Add user">
      <div>
      <label >Type of users:</label>
      <br></br><br></br>
      <select style={{width:"375px",height:"40px"}}>
      <option value="General Practice">Doctor</option>
      <option value="Nurse">Nurse</option>
      <option selected value="Administrator">Administrator</option>
      </select>
       </div> 
      <br></br>
       <div>
      <label>
      Name:
      <input style={{marginRight:"434px",marginLeft:"10px",width:"305px",height:"30px"}} type="text" name="Name" />
      </label>

      <label>
      Surname:
      <input style={{marginRight:"",marginLeft:"10px",width:"343px",height:"30px"}} type="text" name="Surname" />
      </label>
      </div>

      <br></br>

      <div>
      <label>
      ID Number:
      <input style={{marginRight:"436px",marginLeft:"10px",width:"259px",height:"30px"}} type="text" name="ID Number" />
      </label>

      <label>
      Contact Number:
      <input style={{marginRight:"",marginLeft:"10px",width:"272px",height:"30px"}} type="text" name="Contact Number" />
      </label>
      </div>

      <br></br>

      <div className="upload-btn">
      <label>
      Upload Picture<br></br></label>
      <input style = {{height:"45px",width:"1255px"}} type="file" name="upload-btn" />
      
      
      </div>
      <br></br>
      <br></br>
      <br></br>


      </Accordion>

      <Accordion title="Load additional practices">
      
      
      <div style={{height:"100px"}}>
      <label>
      Is your CIPC company registration number/BHF Practice of the additional practice(s) is the same as the one used for this profile?</label><br></br><br></br>
    
      <input style={{marginRight:"10px",marginLeft:"10px",width:"",}} 
      type="radio" 
      name="CIPC" 
      value="No" id="No"  
      checked={selected === "No"}
      onChange={changeHandler}
      />
      <label>Yes</label>
      <input style={{marginRight:"",marginLeft:"10px",width:"",}}   
      type="radio"
      value="Yes"
      id="Yes"
      checked={selected === "Yes"}
      name="CIPC"
      onChange={changeHandler} />
      <label style={{marginRight:"20px",marginLeft:"10px"}}>No</label>
      <div aria-hidden={selected !== "No" ? true : false}>
    <div>
<br></br>
<label>
Practice name:
<input style={{marginRight:"",marginLeft:"128px",width:"238px",height:"30px"}} type="text" name="Practice (Trading As) name" />
</label>
</div>

<br></br>

<div>
<label>
Practice ‘Trading As’ name
<input style={{marginRight:"",marginLeft:"24px",width:"238px",height:"30px"}} type="text" name="CIPC company registration number" />
</label>
</div>
<br></br>


<div>
<label>
Operating location address:
<input style={{marginRight:"",marginLeft:"14px",width:"234px",height:"30px"}} type="text" name="Operating location address" />   
</label>

</div>

    </div>
      
    <br></br><br></br>
    {/* <label htmlFor="No">No</label> */}
    <div aria-hidden={selected !== "Yes" ? true : false}><label>Please repeat the registration process </label>
      
    </div>
      
      </div>
      <br></br>
      <br></br>
      <br></br>
    <br></br>
    <div style = {{marginLeft:"1131px",}}><button style={{height:"40px",width:"120px"}}>Save & Continue</button> </div>
      </Accordion>
      </div>
      </div>
      
             
          
    );
}

export default ManagePractice;
import React from "react";

interface AuthPaneProps {
  children?: React.ReactNode;
  className?: string;
}

const AuthPane: React.FC<AuthPaneProps> = ({
  children,
  className,
}: AuthPaneProps) => {
  return (
    <div className={`auth-pane ${className && className}`}>{children}</div>
  );
};

export default AuthPane;

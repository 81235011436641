import React from "react";

interface ServiceOptionProps {
  selected?: boolean;
  title: string;
  id: number;
  onClick: (serviceOptionId: number) => void;
  cost: number;
  disabled?: boolean;
}

const ServiceOption = ({
  title,
  selected = false,
  onClick,
  id,
  cost,
  disabled = false,
}: ServiceOptionProps) => {
  const handleOnClick = () => {
    onClick(id);
  };
  return (
    <div className={`service-option ${disabled ? "disabled" : ""}`}>
      <label>
        <input
          disabled={disabled}
          onChange={handleOnClick}
          type="checkbox"
          checked={selected}
        />
        <span>{` ${title}`}</span>
      </label>
      <span className="cost"> R{cost.toFixed(2)}</span>
    </div>
  );
};

export default ServiceOption;

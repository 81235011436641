/* eslint-disable react/jsx-props-no-spreading */

import React, { ReactNode, useState } from "react";

import { FiMapPin } from "react-icons/fi";
import PlacesAutocomplete from "react-places-autocomplete";
import useScript from "../../hooks/useScript";

interface AddressLookupProps {
  children: ReactNode;
}

const AddressLookup = ({ children }: AddressLookupProps) => {
  const [address, setAddress] = useState("");
  const searchOptions = {
    types: ["address"],
    componentRestrictions: { country: "za" },
  };
  const status: string = useScript(
    `https://maps.googleapis.com/maps/api/js?key=AIzaSyCFNLDdDsG2LdGVupPkjViO9X1q8foHjCk&libraries=places`,
    false,
  );

  const handleChange = (inputValue: string) => {
    setAddress(inputValue);
  };

  // Strange unique key issue below - even with a key assigned to child objects web tools still throws error
  const renderFunc = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
  }: any) => (
    <div className="autocomplete-root">
      <div>
        {React.cloneElement(children as React.ReactElement<any>, {
          ...getInputProps(),
        })}
      </div>
      <div className="autocomplete-dropdown-container">
        {suggestions.map((suggestion: any) => (
          <div {...getSuggestionItemProps(suggestion)}>
            <span className="autocomplete-suggestion">
              <FiMapPin />
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
  return (
    <div className="address-lookup">
      {status === "loading" ? (
        <div>{children}</div>
      ) : (
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          highlightFirstSuggestion
          searchOptions={searchOptions}
        >
          {renderFunc}
        </PlacesAutocomplete>
      )}
    </div>
  );
};

export default AddressLookup;

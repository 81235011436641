import React from "react";
import { RiCloseCircleLine } from "react-icons/ri";

interface ReversedSlotControlProps {
  onClose: () => void;
  onRemoveReversedSlot: () => void;
}

const ReversedSlotControl = ({
  onClose,
  onRemoveReversedSlot,
}: ReversedSlotControlProps) => {
  return (
    <div className="appointment">
      <div className="title-block">
        <h2>Reserved time slot</h2>
        {onClose && (
          <button onClick={onClose} type="button">
            <RiCloseCircleLine size="2rem" />
          </button>
        )}
      </div>

      <div className="patient-details-block u-margin-top-small">
        {onRemoveReversedSlot && (
          <div>
            <button
              type="button"
              onClick={onRemoveReversedSlot}
              className="cancel-appointment-button u-margin-top-medium"
            >
              Remove reserved slot appointment
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReversedSlotControl;

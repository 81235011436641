import React from "react";
import ReactTooltip from "react-tooltip";

interface StandardRadioSelectProps {
  options: {
    value: any;
    name: string;
    disabled?: boolean;
    tooltip?: string;
    prefix?: string;
  }[];
  selected: any;
  onChange: (selectedOption: any) => void;
}

const StandardRadioSelect = ({
  options,
  onChange,
  selected,
}: StandardRadioSelectProps) => (
  <div className="standard-radio-select">
    {options.map((option) => {
      return (
        <label
          key={option.value}
          className={`radio-item${option.disabled ? " disabled" : ""}`}
          data-tip={option.tooltip}
        >
          {option.tooltip ? <ReactTooltip /> : ""}
          <span className="radio-item-input">
            <input
              type="radio"
              onChange={() => onChange(option.value)}
              checked={option.value === selected}
              className="radio-item-input"
              disabled={option.disabled}
            />
            <span className="radio-item-circle" />
          </span>

          <span className="radio-item-label">
            <div className="radio-item-label--prefix">{option?.prefix}</div>
            {option.name}{" "}
            {option.tooltip && (
              <span className="u-only-mobile">({option.tooltip})</span>
            )}
          </span>
        </label>
      );
    })}
  </div>
);

export default StandardRadioSelect;

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import Cookies from "js-cookie";
import { isEmail } from "../../helpers/regexHelper";
import addEmailToNewsletterMailingList from "../../queries/addEmailToNewsletterMailingList";
import StandardButton from "../shared/StandardButton";
import StandardInput from "../shared/StandardInput";

const NewsletterBlock = () => {
  const [addToMailingList, { called }] = useMutation(
    addEmailToNewsletterMailingList,
  );
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const existingCookie = Cookies.get("viewedNewsletterSignup");
  const handleSignupToNewsletter = () => {
    setError("");

    if (isEmail(email)) {
      addToMailingList({ variables: { email } });
      Cookies.set("viewedNewsletterSignup", "true", { expires: 60 });
    } else {
      setError("Invalid email address.");
      setEmail("");
    }
  };
  if (existingCookie && !called) {
    return null;
  }
  const isMobileDisplay = window.matchMedia("(max-width: 767px)").matches;

  return (
    <div className="newsletter-block">
      <div>
        {called && !error ? (
          <p>Sign up successful!</p>
        ) : (
          <>
            <h1>
              Sign up to the {isMobileDisplay && <br />}Prime Medical Solutions{" "}
              {isMobileDisplay && <br />}
              newsletter
            </h1>
            <p>
              Receive the latest news about our offerings and special health
              hacks
            </p>
            <div className="input-block">
              <StandardInput
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
              />
              {error && <p className="input-error">{error}</p>}

              <StandardButton
                text="Sign Up"
                type="button"
                onClick={handleSignupToNewsletter}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewsletterBlock;

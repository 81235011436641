import { gql } from "@apollo/client";

export default gql`
  query availableAppointments($locationId: Int, $appointmentTypeId: Int) {
    availableAppointments(
      locationId: $locationId
      appointmentTypeId: $appointmentTypeId
    ) {
      doctors {
        id
        firstName
        lastName
        avatarUrl
      }
      startTime
      endTime
    }
  }
`;

import React from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useHistory } from "react-router";

interface FormControlProps {
  children: React.ReactNode;
  onNextButtonClick?: () => void;
  onBackButtonClick?: () => void;
  backButtonVisible?: boolean;
  nextButtonDisabled?: boolean;
  nextButtonVisible?: boolean;
}

const FormControl = ({
  children,
  onNextButtonClick,
  onBackButtonClick,
  nextButtonDisabled = false,
  nextButtonVisible = true,
  backButtonVisible = true,
}: FormControlProps) => {
  const history = useHistory();

  const handleBackButtonClick = () => {
    if (onBackButtonClick) {
      onBackButtonClick();
    } else {
      history.goBack();
    }
  };

  return (
    <div className="form-control">
      <div className="form-content">{children}</div>
      <div className="button-block">
        {backButtonVisible && (
          <button
            className="navigation-button"
            type="button"
            onClick={handleBackButtonClick}
          >
            <FiArrowLeft /> Back
          </button>
        )}

        {nextButtonVisible && (
          <button
            className="navigation-button"
            type="button"
            onClick={onNextButtonClick}
            disabled={nextButtonDisabled}
          >
            Next
            <FiArrowRight />
          </button>
        )}
      </div>
    </div>
  );
};

export default FormControl;

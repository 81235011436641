import { gql } from "@apollo/client";

export default gql`
  query fetchDoctorLocations {
    doctorLocations {
      location {
        id
        description
        city
      }
    }
  }
`;

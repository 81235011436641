import { useQuery } from "@apollo/client";
import React from "react";

import fetchAppointmentTypes from "../../../../queries/fetchAppointmentTypes";
import { IAppointmentType } from "../../../../types";
import StandardError from "../../../shared/StandardError";
import StandardRadioSelect from "../../../shared/StandardRadioSelect";

interface SelectAppointmentTypeProps {
  onChange: (appointmentTypeId: number) => void;
  selectedAppointmentTypeId: number | undefined;
}

const SelectAppointmentType = ({
  onChange,
  selectedAppointmentTypeId,
}: SelectAppointmentTypeProps) => {
  const { loading, error, data } = useQuery(fetchAppointmentTypes);
  if (loading) {
    return <div>Loading</div>;
  }
  if (error) {
    return <StandardError />;
  }
  const availableAppointmentTypes = data.appointmentTypes.map(
    (appointmentType: IAppointmentType) => ({
      value: appointmentType.id,
      name: appointmentType.description,
    }),
  );

  const handleSetSelectedAppointmentType = (appointmentTypeId: string) => {
    onChange(Number.parseInt(appointmentTypeId, 10));
  };
  return (
    <div>
      <h1 className="u-margin-bottom-small">Select appointment type</h1>
      <StandardRadioSelect
        options={availableAppointmentTypes}
        selected={selectedAppointmentTypeId?.toString()}
        onChange={handleSetSelectedAppointmentType}
      />
    </div>
  );
};

export default SelectAppointmentType;

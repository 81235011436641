import { gql } from "@apollo/client";
// todo: should this be a separate query or just a query that filters on appointment status
export default gql`
  query appointmentsAwaitingConfirmation {
    Appointments {
      id
      appointmentTypeId
      patientId
      doctorId
      reservedAppointmentSlotId
      appointmentStatusId
      dateCreated
    }
  }
`;

import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";

import {
  createDoctorAppointmentType,
  deleteDoctorAppointmentType,
  fetchDoctorsWithAppoinmentType,
} from "../../../../queries";

import { IAppointmentType } from "../../../../types";
import AppointmentType from "./AppointmentType";

const ManageAppointmentTypes = () => {
  const { loading, error, data } = useQuery(fetchDoctorsWithAppoinmentType);
  const [addNewDoctorAppointmentType, { loading: loadingAdd }] = useMutation(
    createDoctorAppointmentType,
  );
  const [
    removeNewDoctorAppointmentType,
    { loading: loadingRemove },
  ] = useMutation(deleteDoctorAppointmentType);
  if (loading) return <div>Loading...</div>;
  if (error) return <p>ERROR</p>;
  if (!data) return <p>No doctors found</p>;
  const { appointmentTypes } = data;

  const handleOnChange = (
    doctorId: number,
    appointmentTypeId: number,
    isAdd: boolean,
  ) => {
    if (loadingRemove || loadingAdd) {
      toast.warn("Still loading");
    } else if (isAdd) {
      addNewDoctorAppointmentType({
        variables: {
          input: {
            doctorId: Number(doctorId),
            appointmentTypeId: Number(appointmentTypeId),
          },
        },
      });
    } else {
      removeNewDoctorAppointmentType({
        variables: {
          input: {
            doctorId: Number(doctorId),
            appointmentTypeId: Number(appointmentTypeId),
          },
        },
      });
    }
  };

  return (
    <div className="manage-appointment-types u-margin-top-small">
      Manage appointment types
      {data.doctors.map((doctor: any) => {
        return (
          <div key={doctor.id}>
            <h2 className="u-margin-top-small">
              {`${doctor.firstName} ${" "} ${doctor.lastName}`}
            </h2>
            <div>
              {appointmentTypes.map((appointmentType: IAppointmentType) => {
                const isChecked = doctor.doctorAppointmentTypes.some(
                  (doctorAppointmentType: any) => {
                    return (
                      doctorAppointmentType.appointmentType.id ===
                      appointmentType.id
                    );
                  },
                );
                return (
                  <div
                    className="u-margin-top-small"
                    key={`${doctor.id}_${appointmentType.id}`}
                  >
                    <AppointmentType
                      selected={isChecked}
                      id={appointmentType.id}
                      description={appointmentType.description}
                      onChange={() =>
                        handleOnChange(
                          doctor.id,
                          appointmentType.id,
                          !isChecked,
                        )
                      }
                      disabled={loadingAdd || loadingRemove}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ManageAppointmentTypes;

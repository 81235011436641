/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from "@apollo/client";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { AppointmentTypeEnum } from "../../../../../enums";
import { fetchServiceCategories } from "../../../../../queries";
import { IService } from "../../../../../types";
import StandardError from "../../../../shared/StandardError";
import ServiceCategory from "./ServiceCategory";
import ServiceOption from "./ServiceOption";

interface SelectServiceProps {
  onChangeSelectedServices: (selectedServices: IService[]) => void;
  selectedServices: IService[];
  openCategory: number | undefined;
  onChangeOpenCategory: (categoryId: number) => void;
  selectedAppointmentTypeId: number | undefined;
}

const SelectService = ({
  onChangeSelectedServices,
  selectedServices,
  openCategory,
  onChangeOpenCategory,
  selectedAppointmentTypeId,
}: SelectServiceProps) => {
  const handleSetSelectedService = (service: IService) => {
    // check if the existing list contains the service (ie it is beign removed)
    if (selectedServices.some((selected) => selected.id === service.id)) {
      const result = selectedServices.find(
        (selectedService) =>
          selectedService.dependantOnServiceId === Number(service.id),
      );
      // remove any services dependant on the service being removed
      if (result) {
        onChangeSelectedServices(_.xor(selectedServices, [service, result]));
      } else {
        onChangeSelectedServices(_.xor(selectedServices, [service]));
      }
    } else {
      onChangeSelectedServices(_.xor(selectedServices, [service]));
    }
  };

  const [totalCost, setTotalCost] = useState(0);
  const { loading, error, data } = useQuery(fetchServiceCategories);

  useEffect(() => {
    setTotalCost(
      selectedServices.reduce((total, element) => {
        return total + element.cost;
      }, 0),
    );
  }, [selectedServices]);

  if (loading) return <div>Loading...</div>;
  if (error) return <StandardError />;
  if (!data) return <p>No services available</p>;

  const { serviceCategories } = data;

  const renderServiceCategories = serviceCategories.map(
    (serviceCategory: any) => {
      const filteredServices = serviceCategory.services.filter(
        (option: IService) =>
          option.appointmentTypeId === selectedAppointmentTypeId,
      );
      if (filteredServices.length > 0) {
        return (
          <ServiceCategory
            key={serviceCategory.id}
            title={serviceCategory.description}
            onClick={() => onChangeOpenCategory(serviceCategory.id)}
            open={openCategory === serviceCategory.id}
          >
            {filteredServices.map((option: IService) => {
              return (
                <ServiceOption
                  key={option.id}
                  onClick={() => handleSetSelectedService(option)}
                  title={option.description}
                  id={option.id}
                  selected={selectedServices?.includes(option)}
                  cost={option.cost}
                  disabled={
                    option.dependantOnServiceId !== null &&
                    !selectedServices.some(
                      (serv) => serv.id !== option.dependantOnServiceId,
                    )
                  }
                />
              );
            })}
            {Number(serviceCategory.id) === 4 && (
              <>
                <span className="u-margin-top-small note">
                  Please note that the doctor will confirm your specific
                  vaccination and thereafter, the cost of the vaccination will
                  be added to your consultation fee.
                </span>
                <span className="note u-margin-top-small">
                  <a href="/services" target="_blank">
                    Click here to see the vaccinations price list.
                  </a>
                </span>
              </>
            )}
          </ServiceCategory>
        );
      }
      return null;
    },
  );
  return (
    <div className="select-service">
      <h1>Select services</h1>

      <div style={{ position: "relative" }}>{renderServiceCategories}</div>
      {selectedAppointmentTypeId === AppointmentTypeEnum.Home && (
        <p className="u-margin-top-small">
          <i>
            Please note that as always, we will only charge you for the one-way,
            we will never charge for our healthcare worker’s trip back from your
            home
          </i>
        </p>
      )}
      <h1 className="total-cost">Total: R{totalCost.toFixed(2)}</h1>
    </div>
  );
};

export default SelectService;

import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../Auth";
import { Login } from "../components/auth";

import AuthPane from "../components/auth/AuthPane";

import Layout from "../components/layout/Layout";

const LoginOrRegister = () => {
  const user = useContext(AuthContext);
  if (user.currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <div className="login-or-register u-margin-top-big">
        <AuthPane>
          <Login />
        </AuthPane>
      </div>
    </Layout>
  );
};

export default LoginOrRegister;

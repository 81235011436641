import React from "react";
import {
  FaCalendarPlus,
  FaCheck,
  FaLaptopMedical,
  FaVideo,
} from "react-icons/fa";
import "../../styles/external/frontend.css";

import { AiOutlineHeart } from "react-icons/ai";
import { RiStethoscopeLine } from "react-icons/ri";

const OldHomePage = () => {
  return (
    <div className="elementor elementor-7 elementor-section-wrap old-home-page">
      <section
        className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-758e365 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="758e365"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fe2829c"
            data-id="fe2829c"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-66a5ca99 elementor-widget elementor-widget-heading"
                data-id="66a5ca99"
                data-element_type="widget"
                data-widget_type="heading.default"
              />
              <div
                className="elementor-element elementor-element-7f93922 elementor-hidden-desktop elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-text-editor"
                data-id="7f93922"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-text-editor elementor-clearfix">
                    <p>
                      You can now consult with your doctor through your phone or
                      laptop via a video platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <header
        className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-58462e62 elementor-section-content-middle elementor-hidden-desktop elementor-hidden-tablet elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="58462e62"
        data-element_type="section"
        data-settings='{"background_background":"classic","sticky":"top","sticky_on":["desktop","tablet","mobile"],"sticky_offset":0,"sticky_effects_offset":0}'
      >
        <div className="elementor-container elementor-column-gap-no">
          <div
            className="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-da3a937"
            data-id="da3a937"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-243bef4 elementor-widget elementor-widget-text-editor"
                data-id="243bef4"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-text-editor elementor-clearfix">
                    <p>QUICK SERVICES NAVIGATION</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section
        className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-268d3a6f elementor-reverse-mobile elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="268d3a6f"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3287c4c3"
            data-id="3287c4c3"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-128f930 elementor-widget elementor-widget-heading"
                data-id="128f930"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    1
                  </h4>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-a79e674 elementor-widget elementor-widget-heading"
                data-id="a79e674"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    Virtual Doctor Visit
                  </h4>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-7834a60 elementor-widget elementor-widget-text-editor"
                data-id="7834a60"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-text-editor elementor-clearfix">
                    <div className="page" title="Page 1">
                      <div className="layoutArea">
                        <div className="column">
                          <p>
                            Our doctor connects with you via video or telephone
                            consultation to perform the following:
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-2da3bcf elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                data-id="2da3bcf"
                data-element_type="widget"
                data-widget_type="icon-list.default"
              >
                <div>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Covid Assessment and referral for testing{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Assessment of acute medical conditions such as flu-like
                        symptoms, diarrhoea, vomiting, urinary tract infections,
                        skin rashes, allergies etc
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        General medical queries{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Referral to laboratory for blood/urine tests{" "}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-4027e13a elementor-align-right elementor-widget elementor-widget-button"
                data-id="4027e13a"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      href="/booking"
                      className="elementor-button-link elementor-button elementor-size-sm elementor-animation-sink"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-left">
                          <FaVideo />
                        </span>
                        <span className="elementor-button-text">
                          BOOK VIRTUAL DOCTOR APPOINTMENT
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-63b274a8"
            data-id="63b274a8"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-482e2b99 elementor-widget elementor-widget-spacer"
                data-id="482e2b99"
                data-element_type="widget"
                data-widget_type="spacer.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
                    <div className="elementor-spacer-inner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-a198574 elementor-reverse-mobile elementor-hidden-desktop elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="a198574"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d7b1e32"
            data-id="d7b1e32"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-03be2ae elementor-widget elementor-widget-heading"
                data-id="03be2ae"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    1
                  </h4>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-dfc5181 elementor-widget elementor-widget-heading"
                data-id="dfc5181"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    Virtual Doctor Visit
                  </h4>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-7834a60 elementor-widget elementor-widget-text-editor"
                data-id="7834a60"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-text-editor elementor-clearfix">
                    <div className="page" title="Page 1">
                      <div className="layoutArea">
                        <div className="column">
                          <p>
                            Our doctor connects with you via video or telephone
                            consultation to perform the following:
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-c5c89c3 elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                data-id="c5c89c3"
                data-element_type="widget"
                data-widget_type="icon-list.default"
              >
                <div>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Covid Assessment and referral for testing{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Assessment of acute medical conditions such as flu-like
                        symptoms, diarrhoea, vomiting, urinary tract infections,
                        skin rashes, allergies etc
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        General medical queries{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Referral to laboratory for blood/urine tests{" "}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-bc127ff elementor-align-left elementor-widget elementor-widget-button"
                data-id="bc127ff"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      href="/product/video-consultation/"
                      className="elementor-button-link elementor-button elementor-size-sm elementor-animation-sink"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-left">
                          <FaVideo />
                        </span>
                        <span className="elementor-button-text">
                          BOOK VIRTUAL DOCTOR APPOINTMENT
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ef239e0"
            data-id="ef239e0"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-d3170f0 elementor-widget elementor-widget-spacer"
                data-id="d3170f0"
                data-element_type="widget"
                data-widget_type="spacer.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
                    <div className="elementor-spacer-inner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-ea6592a elementor-reverse-mobile elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="ea6592a"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-83c3cfe"
            data-id="83c3cfe"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-9651c9f elementor-widget elementor-widget-spacer"
                data-id="9651c9f"
                data-element_type="widget"
                data-widget_type="spacer.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
                    <div className="elementor-spacer-inner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6b7accf"
            data-id="6b7accf"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-7fe6210 elementor-widget elementor-widget-menu-anchor"
                data-id="7fe6210"
                data-element_type="widget"
                data-widget_type="menu-anchor.default"
              >
                <div className="elementor-widget-container">
                  <div id="Covidassesment" className="elementor-menu-anchor" />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-af94537 elementor-widget elementor-widget-heading"
                data-id="af94537"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    2
                  </h4>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-d056660 elementor-widget elementor-widget-heading"
                data-id="d056660"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    Home/Office Consultations
                  </h4>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-7834a60 elementor-widget elementor-widget-text-editor"
                data-id="7834a60"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-text-editor elementor-clearfix">
                    <div className="page" title="Page 1">
                      <div className="layoutArea">
                        <div className="column">
                          <p>
                            Our house call doctor travels to your home/office to
                            assess and provide you with the necessary treatment.
                            See a PMSI doctor in Johannesburg or Cape Town who
                            can offer you:
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-a279981 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                data-id="a279981"
                data-element_type="widget"
                data-widget_type="icon-list.default"
              >
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        COVID swabs done at your home{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Management of chronic conditions such as hypertension,
                        diabetes, cholesterol, HIV, asthma etc.
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Assessment of blood pressure, sugar levels and urine
                        testing
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Detailed examination related to your main complaint{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Administer medication via a drip{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Management of wounds including stitching wounds,
                        cleaning and dressing wounds, post-op wound care
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Family planning (such as administration of 3 monthly
                        depo-provera injection)
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Vaccinations (childhood and travel){" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Assessment for insurance policies and pre-employment
                        fitness{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Drawing of blood tests done at your home/office{" "}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-205585f elementor-align-left elementor-widget elementor-widget-button"
                data-id="205585f"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      href="/booking"
                      className="elementor-button-link elementor-button elementor-size-sm elementor-animation-sink"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-left">
                          <FaCalendarPlus />
                        </span>

                        <span className="elementor-button-text">
                          BOOK APPOINTMENT
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-9e8e391 elementor-reverse-mobile elementor-hidden-desktop elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="9e8e391"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8a920f6"
            data-id="8a920f6"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-e024b7e elementor-widget elementor-widget-menu-anchor"
                data-id="e024b7e"
                data-element_type="widget"
                data-widget_type="menu-anchor.default"
              >
                <div className="elementor-widget-container">
                  <div id="Covidassesment" className="elementor-menu-anchor" />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-0dcff4c elementor-widget elementor-widget-heading"
                data-id="0dcff4c"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    2
                  </h4>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-0d118dc elementor-widget elementor-widget-heading"
                data-id="0d118dc"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    Home/Office Consultations
                  </h4>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-9c40d13 elementor-widget elementor-widget-text-editor"
                data-id="9c40d13"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-text-editor elementor-clearfix">
                    <div className="page" title="Page 1">
                      <div className="layoutArea">
                        <div className="column">
                          <p>
                            Our house call doctor travels to your home/office to
                            assess and provide you with the necessary treatment.
                            See a PMSI doctor in Johannesburg or Cape Town who
                            can offer you:
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-76c0c3c elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                data-id="76c0c3c"
                data-element_type="widget"
                data-widget_type="icon-list.default"
              >
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        COVID swabs done at your home{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Management of chronic conditions such as hypertension,
                        diabetes, cholesterol, HIV, asthma etc.
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Assessment of blood pressure, sugar levels and urine
                        testing
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Detailed examination related to your main complaint{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Administer medication via a drip{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Management of wounds including stitching wounds,
                        cleaning and dressing wounds, post-op wound care
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Family planning (such as administration of 3 monthly
                        depo-provera injection)
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Vaccinations (childhood and travel){" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Assessment for insurance policies and pre-employment
                        fitness{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Drawing of blood tests done at your home/office{" "}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-81066ad elementor-align-left elementor-widget elementor-widget-button"
                data-id="81066ad"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      href="/booking"
                      className="elementor-button-link elementor-button elementor-size-sm elementor-animation-sink"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-left">
                          <FaCalendarPlus />
                        </span>
                        <span className="elementor-button-text">
                          BOOK APPOINTMENT
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-35adb38"
            data-id="35adb38"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-76f89b8 elementor-widget elementor-widget-spacer"
                data-id="76f89b8"
                data-element_type="widget"
                data-widget_type="spacer.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
                    <div className="elementor-spacer-inner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-7b62a53f elementor-reverse-mobile elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="7b62a53f"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5ca29388"
            data-id="5ca29388"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-9682dec elementor-widget elementor-widget-menu-anchor"
                data-id="9682dec"
                data-element_type="widget"
                data-widget_type="menu-anchor.default"
              >
                <div className="elementor-widget-container">
                  <div
                    id="IntravenousVitaminTherapy"
                    className="elementor-menu-anchor"
                  />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-99b187c elementor-widget elementor-widget-heading"
                data-id="99b187c"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    3
                  </h4>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-94db57f elementor-widget elementor-widget-heading"
                data-id="94db57f"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    Intravenous Vitamin Therapy
                  </h4>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-ae39382 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                data-id="ae39382"
                data-element_type="widget"
                data-widget_type="icon-list.default"
              >
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        {" "}
                        Immune Boost: Strengthen your Immune System to fight off
                        infections with high dose Vitamin C and Zinc.
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Detox: Assist your body in eliminating those unwanted
                        toxins with Selenium, Zinc and Glutathione.
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Skin & Hair Glow: Get healthier, brighter, looking skin
                        and hair with our combination of selenium, Zinc,
                        Glutathione, Vitamin A, C and E.
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Energy boost: Say goodbye to fatigue and hello to high
                        productivity by taking our Energy Boost IV drip which
                        consists of Vitamin B12 and Magnesium Sulphate.
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Anti-Inflammatory: Reduce joint and muscle aches with
                        our combination of Vitamin B12, Magnesium Sulphate,
                        Selenium, Zinc, Glutathione and High dose Vitamin C.
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Jet Fuel: Cure your hangover with our combination of
                        Vitamin B12, Thiamine, Magnesium sulphate, Zinc,
                        Glutathione and Vitamin C.
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Sports Refuel: Quicken your recovery period between
                        sessions with our combination of Vitamin B12, Magnesium
                        sulphate, Zinc and Glutathione.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-4cccfb3 elementor-align-left elementor-widget elementor-widget-button"
                data-id="4cccfb3"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      href="/booking"
                      className="elementor-button-link elementor-button elementor-size-sm elementor-animation-sink"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-left">
                          <FaCalendarPlus />
                        </span>
                        <span className="elementor-button-text">
                          BOOK APPOINTMENT
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-15a9671e"
            data-id="15a9671e"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-62cdb896 elementor-widget elementor-widget-spacer"
                data-id="62cdb896"
                data-element_type="widget"
                data-widget_type="spacer.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
                    <div className="elementor-spacer-inner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-30bb530 elementor-reverse-mobile elementor-hidden-desktop elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="30bb530"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54baa37"
            data-id="54baa37"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-84f0d3c elementor-widget elementor-widget-menu-anchor"
                data-id="84f0d3c"
                data-element_type="widget"
                data-widget_type="menu-anchor.default"
              >
                <div className="elementor-widget-container">
                  <div
                    id="IntravenousVitaminTherapy"
                    className="elementor-menu-anchor"
                  />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-1b15d39 elementor-widget elementor-widget-heading"
                data-id="1b15d39"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    3
                  </h4>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-54f0079 elementor-widget elementor-widget-heading"
                data-id="54f0079"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-title elementor-size-default">
                    Intravenous Vitamin Therapy
                  </h4>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-a0aac9d elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                data-id="a0aac9d"
                data-element_type="widget"
                data-widget_type="icon-list.default"
              >
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        {" "}
                        Immune Boost: Strengthen your immune system to fight
                        infections with high dose Vitamin C{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Detox: Assist your body in eliminating toxins with
                        Glutathione{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Skin & Hair Glow: Get healthier, brighter looking skin
                        and hair with a combination of Glutathione and Vitamin C{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Energy boost: Say goodbye to fatigue with a combination
                        of Vitamin B Complex, Vitamin B12, Magnesium Sulphate{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Anti-Inflammatory: Reduce joint and muscle aches with a
                        combination of Vitamin B12, Vitamin B Complex, Magnesium
                        Sulphate, Glutathione and high dose Vitamin C{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Jet Fuel: Cure your hangover with a combination of
                        Vitamin B Complex, Vitamin B12, Magnesium Sulphate.
                        Glutathione and Vitamin C{" "}
                      </span>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <FaCheck />
                      </span>
                      <span className="elementor-icon-list-text">
                        Sports Refuel: quicken your recovery period between
                        sessions with a combination of Vitamin B12, Vitamin B
                        Complex, Magnesium Sulphate and Glutathione{" "}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-f1b2cc0 elementor-align-left elementor-widget elementor-widget-button"
                data-id="f1b2cc0"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      href="/booking"
                      className="elementor-button-link elementor-button elementor-size-sm elementor-animation-sink"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-left">
                          <FaCalendarPlus />
                        </span>
                        <span className="elementor-button-text">
                          BOOK APPOINTMENT
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ba42efd"
            data-id="ba42efd"
            data-element_type="column"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-b773a3f elementor-widget elementor-widget-spacer"
                data-id="b773a3f"
                data-element_type="widget"
                data-widget_type="spacer.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
                    <div className="elementor-spacer-inner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-f46d300 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="f46d300"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-63426c1"
            data-id="63426c1"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-36e222fb elementor-widget elementor-widget-heading"
                data-id="36e222fb"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    WHY CHOOSE US
                  </h2>{" "}
                </div>
              </div>
              <div
                className="elementor-element elementor-element-4c232a4 elementor-widget elementor-widget-text-editor"
                data-id="4c232a4"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container why-choose-us-intro">
                  <div className="elementor-text-editor elementor-clearfix">
                    <p>
                      Our mission is to create healthier communities by
                      enhancing access to healthcare through the innovative
                      application of technology within the healthcare space. Our
                      professionally trained practitioners go above and beyond
                      to deliver nation-wide virtual healthcare as we heal South
                      Africa one patient at a time
                    </p>
                  </div>
                </div>
              </div>
              <section className="why-choose-us-reasons">
                <div className="reason">
                  <RiStethoscopeLine fontSize="5rem" />

                  <h3 className="elementor-image-box-title">
                    Professional Doctors
                  </h3>
                  <p className="elementor-image-box-description">
                    All our doctors are professionally trained with vast years
                    of experience in medical practice. All our doctors are
                    registered with the Health Professions Council of South
                    Africa.
                  </p>
                </div>
                <div className="reason">
                  <FaLaptopMedical fontSize="5rem" />
                  <h3 className="elementor-image-box-title">
                    Ultimate Convenience
                  </h3>
                  <p className="elementor-image-box-description">
                    We provide you with unique access to a range of quality
                    healthcare services at our consultation rooms, virtually via
                    video call as well as in the comfort of your home. It
                    doesn’t get any more convenient than that!
                  </p>
                </div>
                <div className="reason">
                  <AiOutlineHeart fontSize="5rem" />
                  <h3 className="elementor-image-box-title">We Care</h3>
                  <p className="elementor-image-box-description">
                    We exist to be partners with our patient, not only during
                    periods of acute health matters, but also through your
                    health journey by creating a platform that allows you to
                    access healthcare as speedily and safely as possible
                  </p>
                </div>
              </section>

              <div
                className="elementor-element elementor-element-5704684c elementor-align-center elementor-mobile-align-center elementor-widget elementor-widget-button"
                data-id="5704684c"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      href="/booking"
                      className="elementor-button-link elementor-button elementor-size-md elementor-animation-grow"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon elementor-align-icon-left">
                          <i aria-hidden="true" className="fas fa-video" />{" "}
                        </span>
                        <span className="elementor-button-text">
                          BOOK A CONSULTATION
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OldHomePage;

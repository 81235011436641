import React from "react";
import Layout from "../layout/Layout";

function Confirmation(){
    return (  
        <Layout>
            
            <div style ={{height:"59vh"}} className="Register2" >
      {/* <div className="progressbar">
        <div style={{width: page === 0 ? "20%" : page === 1 ? "40%" :  page === 2 ? "60%" :page === 3 ? "80%" :"100%"}}></div>
      </div> */}
             
          <br></br>
          
          <div style={{width:"550px" ,height:"400px"}} className="form-container">
          <label style = {{fontSize:"29px"}}><strong>Thank you for your submission,your application will be notified to an admin to process and review your application</strong></label>
        </div>
        </div>
        
        </Layout>
    );
}

export default Confirmation;
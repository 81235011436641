import { gql } from "@apollo/client";

export default gql`
  mutation cancelAppointment($appointmentId: ID!, $zoomMeetingId: String) {
    cancelAppointment(
      appointmentId: $appointmentId
      zoomMeetingId: $zoomMeetingId
    ) {
      id
    }
  }
`;

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

interface StandardButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

const StandardButton = ({ text, ...props }: StandardButtonProps) => {
  return (
    <button className="standard-button" type="button" {...props}>
      {text}
    </button>
  );
};

export default StandardButton;

import React, { ReactNode, useRef, useState } from "react";
import { FaChevronCircleRight } from "react-icons/fa";

interface AccordionProps {
  children: ReactNode;
  title: string;
}

const Accordion = ({ children, title }: AccordionProps) => {
  const [open, setOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState("0px");
  const [iconClass, setIconClass] = useState("accordion-icon");

  const content = useRef<HTMLElement | null>(null);

  const handleSetOpen = () => {
    if (content && content.current) {
      setContentHeight(
        open === true ? "0px" : `${content.current.scrollHeight + 20}px`,
      );
    }
    setIconClass(open ? "accordion-icon" : "accordion-icon rotate");
    setOpen(!open);
  };

  return (
    <div className="accordion u-margin-padding-small">
      <button type="button" onClick={handleSetOpen} className="title">
        <h2>{title}</h2>

        <FaChevronCircleRight className={`${iconClass}`} />
      </button>
      <section ref={content} style={{ maxHeight: `${contentHeight}` }}>
        <div className="content-block">{children}</div>
      </section>
    </div>
  );
};

export default Accordion;

import { gql } from "@apollo/client";

export default gql`
  query fetchServicesCategories {
    serviceCategories {
      id
      description
      services {
        id
        description
        appointmentTypeId
        cost
        dependantOnServiceId
      }
    }
  }
`;

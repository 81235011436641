/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

interface StandardInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  variant?: "dark";
  inputRef?: any;
  error?: boolean;
  errorMessage?: string;
}

const StandardInput = ({
  label,
  variant,
  inputRef,
  error,
  errorMessage = undefined,
  ...props
}: StandardInputProps) => {
  return (
    <div
      className={`standard-input${variant === "dark" ? " dark" : ""} ${
        error ? "error" : ""
      }`}
    >
      <label>
        {label}
        <input ref={inputRef} {...props} />
      </label>
      {error && errorMessage && (
        <span className="error-message">{errorMessage}</span>
      )}
    </div>
  );
};

export default StandardInput;

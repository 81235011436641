import React, { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import firebase from "../../firebase";
import Loader from "../shared/Loader";
import StandardButton from "../shared/StandardButton";
import StandardInput from "../shared/StandardInput";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [error, setError] = useState(null);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === "email") {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
      } catch ({ message }) {
        setIsLoading(false);

        setError(message);
      } finally {
        history.push("/admin");
      }
    },
    [history, email, password],
  );

  return (
    <div className="login">
      {error !== null && <h1>{error}</h1>}
      <h2 className="u-margin-bottom-small">
        Welcome back. Please sign in below
      </h2>
      <form>
        <StandardInput
          name="email"
          placeholder="youremail@address.com"
          value={email}
          onChange={(e) => handleOnChange(e)}
          label="Email address"
        />
        <StandardInput
          onChange={(e) => handleOnChange(e)}
          value={password}
          placeholder="Password"
          name="password"
          type="password"
          label="Password"
        />
        <Link className="forgot-password" to="reset-password">
          Forgot your password?
        </Link>

        {isLoading ? (
          <Loader />
        ) : (
          <StandardButton text="Sign In" type="submit" onClick={handleLogin} />
        )}
      </form>
    </div>
  );
};

export default Login;

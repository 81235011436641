import React from "react";

const CallOutNotice = () => {
  return (
    <p className="u-margin-top-small call-out-notice">
      All home consults within a 20km distance have a base call out fee of R150.{" "}
      <br />
      Additional R4.50 p/km will be charged for locations further than 20km’s
    </p>
  );
};

export default CallOutNotice;

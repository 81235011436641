import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "../../firebase";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === "email") {
      setEmail(e.target.value);
    }
  };
  const sendResetEmail = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await firebase.auth().sendPasswordResetEmail(email);
        setResetEmailSent(true);
      } catch ({ message }) {
        setError(message);
      }
    },
    [email],
  );
  return (
    <div>
      <h1>Reset your Password</h1>
      <div>
        <form onSubmit={sendResetEmail}>
          {resetEmailSent && <div>Password reset email sent</div>}
          {error !== null && <div>{error}</div>}
          <label htmlFor="userEmail">Email:</label>
          <input
            type="email"
            name="email"
            value={email}
            placeholder="Input your email"
            onChange={handleOnChange}
          />
          <button type="submit">Email password reset link </button>
        </form>
        <Link to="/"> Sign in</Link>
      </div>
    </div>
  );
};
export default PasswordReset;

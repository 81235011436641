import { gql } from "@apollo/client";

export default gql`
  query fetchAppointments {
    appointments {
      id
      startDate
      endDate
      appointmentLink {
        id
        url
      }
      appointmentType {
        id
        description
      }
      patient {
        firstName
        lastName
        emailAddress
        mobileNumber
      }
      appointmentStatus {
        id
        description
      }
      appointmentLocation {
        id
        location
      }
    }
  }
`;

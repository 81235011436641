import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import Layout from "../../components/layout/Layout";
import AppointmentForm from "../../components/patient/booking/AppointmentForm";

const Booking = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Route path={path}>
          <AppointmentForm />
        </Route>
      </Switch>
    </Layout>
  );
};

export default Booking;


import React, { useState } from "react";
import Layout from "../layout/Layout";
import Accordion from "../shared/Accordion";
// import { Link } from "react-router-dom";
import "../Register.css";


function Register(){

  const [name, setMessage] = useState('');
  
  
  const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setMessage(event.target.value);}
    
 const [surname, setMessage2] = useState('');
 const handleChange2 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
  setMessage2(event.target.value);}

  const [idnumber, setMessage3] = useState('');
  const handleChange3 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
   setMessage3(event.target.value);}

   const [contactnumber, setMessage4] = useState('');
   const handleChange4 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setMessage4(event.target.value);}

    const [email, setMessage5] = useState('');
    const handleChange5 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
     setMessage5(event.target.value);}
  
  const [hpcsanumber, setMessage6] = useState('');
   const handleChange6 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setMessage6(event.target.value);}

    const [practicebhfnumb, setMessage7] = useState('');
    const handleChange7 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
     setMessage7(event.target.value);}

     const [practicename, setMessage8] = useState('');
     const handleChange8 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setMessage8(event.target.value);}

      const [cipccompany, setMessage9] = useState('');
      const handleChange9 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
       setMessage9(event.target.value);}

       const [pdcontactnumber, setMessage10] = useState('');
       const handleChange10 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setMessage10(event.target.value);}

        const [vatnumber, setMessage11] = useState('');
        const handleChange11 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
         setMessage11(event.target.value);}

         const [operatinglocation, setMessage12] = useState('');
        const handleChange12 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
         setMessage12(event.target.value);}

         const [accountname, setMessage13] = useState('');
        const handleChange13 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
         setMessage13(event.target.value);}

         const [accountnumber, setMessage14] = useState('');
        const handleChange14 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
         setMessage14(event.target.value);}

         const [branchnumber, setMessage15] = useState('');
        const handleChange15 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
         setMessage15(event.target.value);}

         const [bankname, setMessage16] = useState('');
        const handleChange16 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
         setMessage16(event.target.value);}

         const [message17, setMessage17] = useState('');
         const handleChange17 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
          setMessage17(event.target.value);}

          const [typeofpractice, setMessage18] = useState('');
         const handleChange18 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
          setMessage18(event.target.value);}

          
          const [message19, setMessage19] = useState('');
         const handleChange19 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
          setMessage19(event.target.value);}

          const [message20, setMessage20] = useState('');
         const handleChange20 = (event: { target: { value: React.SetStateAction<string>; }; }) => {
          setMessage20(event.target.value);}

          


    const handleSubmit = () => 
    {
      if(name.length === 0){alert ("Name has been left blank");}
      else if (surname.length === 0){alert ("The Surname field has been left blank");}
      else if (idnumber.length === 0){alert ("The Id number field has been left blank");}
      else if (contactnumber.length === 0){alert ("The Contact number field has been left blank");}
      else if (email.length === 0){alert ("The Email field has been left blank");}
      else if (hpcsanumber.length === 0){alert ("The HPCSA (MP) Number field has been left blank");}

      else{alert ("All fields have been filled in please continue to the next section");}
    }

    const handleSubmit2 = () => 
    {
      if(practicebhfnumb.length === 0){alert ("The Practice BHF number field has been left blank");}
      else if (practicename.length === 0){alert ("The Practice name field has been left blank");}
      else if (cipccompany.length === 0){alert ("The CIPC company registration number field has been left blank");}
      else if (pdcontactnumber.length === 0){alert ("The Contact number field has been left blank");}
      else if (vatnumber.length === 0){alert ("The VAT number field has been left blank");}
      else if (typeofpractice.length === 0){alert ("The Type of practice field has been left blank");}
      else if (operatinglocation.length === 0){alert ("The Operating location address field has been left blank");}
     
      else{alert ("All fields have been filled in please continue to the next section");}
    }

    const handleSubmit3 = () => 
    {
      if(accountname.length === 0){alert ("The Account Name field has been left blank");}
      else if (accountnumber.length === 0){alert ("The Account Number field has been left blank");}
      else if (branchnumber.length === 0){alert ("The Branch Number field has been left blank");}
      else if (bankname.length === 0){alert ("The Bank Name field has been left blank");}

      // else{alert ("All fields have been filled in please continue to the confirmation section ,if all details are correct please Submit details");}

  
    }
 
  


  return (
    <Layout>
     
    <div className="Register" >
      {/* <div className="progressbar">
        <div style={{width: page === 0 ? "20%" : page === 1 ? "40%" :  page === 2 ? "60%" :page === 3 ? "80%" :"100%"}}></div>
      </div> */}
      <div className="header2">
          <br></br>
          <label style = {{fontSize:"40px"}}><strong>Healthcare worker registration</strong></label>
        </div>
      <div className="form-container">
        <Accordion title="Personal Details">
        <div>
        <label>
        Name:
        <input style={{marginRight:"40px",marginLeft:"10px",width:"305px",height:"30px"}} 
          type="text"
          id="name"
          name="name"
          onChange={handleChange}
          value={name}
        />
        </label>
        <label>
        Surname:
        <input style={{marginRight:"0px",marginLeft:"79px",width:"273px",height:"30px"}} 
          type="text"
          id="surname"
          name="surname"
          onChange={handleChange2}
          value={surname}
        />
        </label>
        </div>

        <br></br>

        <div>
        <label>
        ID Number:
        <input style={{marginRight:"40px",marginLeft:"10px",width:"259px",height:"30px"}} type="text"
          id="idnumber"
          name="idnumber"
          onChange={handleChange3}
          value={idnumber}
         />
        </label>

        <label>
        Contact Number:
        <input style={{marginRight:"",marginLeft:"10px",width:"272px",height:"30px"}} 
          id="contactnumber"
          name="contactnumber"
          onChange={handleChange4}
          value={contactnumber}
          />
        </label>
        </div>

        <br></br>

        <div>
        <label>
        Email:
        <input style={{marginRight:"",marginLeft:"10px",width:"308px",height:"30px"}} 
         id="email"
         name="email"
         onChange={handleChange5}
         value={email}
        />
        </label>
        <br></br><br></br>
        <label>
        HPCSA (MP) Number:
        <input style={{marginRight:"",marginLeft:"10px",width:"165px",height:"30px"}} 
         id="message6"
         name="hpcsanumber"
         onChange={handleChange6}
         value={hpcsanumber} />
        </label>
        </div>

        <br></br>

        

        
        <div className="upload-btn">
        <label>
        Uploads (ID and Proof of residence )<br></br></label>
        <input style = {{height:"45px",width:"855px" ,}} 
        type="file"  
        id="upload-btn"
        name="upload-btn"
        onChange={handleChange17}
        value={message17}/>
        
        
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div style = {{marginLeft:"680px",}}><button style={{height:"40px",width:"120px"}} onClick={handleSubmit}>Save</button></div><br></br>
        
        </Accordion>

        <Accordion title="Practice Details">
        <div>
        <label>
        Practice BHF number:
        <input style={{marginRight:"10px",marginLeft:"10px",width:"291px",height:"30px"}} 
         id="practicebhfnumb"
         name="practicebhfnumb"
         onChange={handleChange7}
         value={practicebhfnumb} /> 
        </label>

        <label>
        Practice name:
        <input style={{marginRight:"",marginLeft:"10px",width:"197px",height:"30px"}} 
         id="practicenumber"
         name="practicenumber"
         onChange={handleChange8}
         value={practicename} />  
        </label>
        </div>

        <br></br>

        <div>
        <label>
        CIPC company registration number:
        <input style={{marginRight:"",marginLeft:"10px",width:"164px",height:"30px"}} 
        id="cipccompany"
        name="cipccompany"
        onChange={handleChange9}
        value={cipccompany} />
        </label>

       
        </div>

        <br></br>

        <div> <label>
        Contact Number:
        <input style={{marginRight:"",marginLeft:"10px",width:"333px",height:"30px"}} 
        id="pdcontactnumber"
        name="pdcontactnumber"
        onChange={handleChange10}
        value={pdcontactnumber} />
        </label></div>

        <div>
          <br></br>
        <label>
        VAT number :
        <input style={{marginRight:"",marginLeft:"10px",width:"367px",height:"30px"}}
        id="vatdetails"
        name="vatdetails"
        onChange={handleChange11}
        value={vatnumber} />
        </label>
        </div>

        <br></br>

        <div>
        <label>Type of practice</label>
        <br></br>
        <select style={{width:"499px",height:"40px"}}   onChange={handleChange18} value={typeofpractice} >
        <option value="General Practice">General Practice</option>
        <option value="Dental">Dental</option>
        <option selected value="coconut">Specialist</option>
        <option value="Allied Health">Allied Health</option>
        <option value="Speech">Speech</option>
        <option value="Other">Other</option>
        </select>
         </div> 
        <br></br>

        <div>
        <label>
        Operating location address:
        <input style={{marginRight:"",marginLeft:"10px",width:"235px",height:"30px"}} 
        id="operatinglocation"
        name="operatinglocation"
        onChange={handleChange12}
        value={operatinglocation} />   
        </label>
        </div>
        <br></br>
        <div className="upload-btn2">
        <label>
        Uploads ( Proof of operating location, Company registration document, Proof of practice BHF number, VAT registration certificatee)<br></br></label>
        <input style = {{height:"45px",width:"855px"}}
           type="file"  
          id="upload-btn2"
          name="upload-btn2"
          onChange={handleChange19}
          value={message19}/>
        
        
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div style = {{marginLeft:"680px",}}><button style={{height:"40px",width:"120px"}} onClick={handleSubmit2}>Save</button></div><br></br>
        </Accordion>
        
        
        <Accordion title="Banking Details">
        <div>
        <label>
        Account Name:
        <input style={{marginRight:"0px",marginLeft:"18px",width:"320px",height:"30px"}}
         id="accountname"
         name="accountname"
         onChange={handleChange13}
         value={accountname} />    
        </label>

        <label style={{marginLeft:"10px"}}>
        Account Number:
        <input style={{marginRight:"0px",marginLeft:"3px",width:"193px",height:"30px"}} 
          id="accountnumber"
          name="accountnumber"
          onChange={handleChange14}
          value={accountnumber}/>
        </label>
        </div>

        <br></br>

        <div>
        <label>
        Branch Number:
        <input style={{marginRight:"10px",marginLeft:"10px",width:"318px",height:"30px"}}
         id="branchnumber"
         name="branchnumber"
         onChange={handleChange15}
         value={branchnumber}/>
        </label>
        <label>
        Bank Name:
        <input style={{marginRight:"",marginLeft:"10px",width:"238px",height:"30px"}} 
        id="bankname"
        name="bankname"
        onChange={handleChange16}
        value={bankname}/> 
        </label>
       
        </div>

        <br></br>
        <div className="upload-btn">
        <label>
        Uploads (Bank Confirmation Letter)<br></br></label>
        <input style = {{height:"45px",width:"849px"}} 
        type="file"  
        id="upload-btn3"
        name="upload-btn3"
        onChange={handleChange20}
        value={message20}/>
        
        
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div style = {{marginLeft:"680px",}}><button style={{height:"40px",width:"120px"}} onClick={handleSubmit3}>Save</button></div><br></br>
        </Accordion>
        {/* <div>
          <label>0% complete : </label> 
          <progress id = "file" max="100" value="70">70%</progress>
        </div> */}
        <Accordion title="Confirmation">
      
        <div><h2>Personal Details</h2></div><br></br>
        <div>
        
        <label style={{marginRight:"40px",marginLeft:"0px",width:"259px",height:"30px"}}>Name: {name}
        
        </label><br></br><br></br>
        </div>
        <div><label style={{marginRight:"40px",marginLeft:"0px",width:"259px",height:"30px"}}>Surname: {surname}
        </label></div>
        
        

        <br></br>

        <div>
        <label style={{marginRight:"40px",marginLeft:"0px",width:"259px",height:"30px"}}>
        ID Number:{idnumber}
       
        </label><br></br><br></br>
</div>
        <label style={{marginRight:"",marginLeft:"0px",width:"272px",height:"30px"}}>
        Contact Number:{contactnumber}
        
        </label>
        

        <br></br><br></br>

        <div>
        <label style={{marginRight:"",marginLeft:"0px",width:"308px",height:"30px"}}>
        Email:{email}
        
        </label>
        <br></br><br></br>
        <label style={{marginRight:"",marginLeft:"0px",width:"",height:"30px"}}>
        HPCSA (MP) Number:{hpcsanumber}
        
        </label>
        </div>

        <br></br>

        

        <div className="upload-btn">
        <label style = {{height:"45px",width:"855px"}}>
        Uploads (ID and Proof of residence ):{message17}
        <br></br></label>
        
        
        
        
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div><h2>Practice Details</h2></div>
        <br></br>
        <div>
        <label style={{marginRight:"10px",marginLeft:"0px",width:"291px",height:"30px"}}>
        Practice BHF number:{practicebhfnumb}
       
        </label>
       </div><br></br>
        <label style={{marginRight:"",marginLeft:"0px",width:"197px",height:"30px"}} >
        Practice name:{practicename}
        
        </label>
       

        <br></br><br></br>

        <div>
        <label style={{marginRight:"",marginLeft:"0px",width:"",height:"30px"}}>
        CIPC company registration number:{cipccompany}
       
        </label>

       
        </div>

        <br></br>

        <div> <label style={{marginRight:"",marginLeft:"0px",width:"333px",height:"30px"}}>
        Contact Number:{pdcontactnumber}
        
        </label></div>

        <div>
          <br></br>
        <label style={{marginRight:"",marginLeft:"0px",width:"367px",height:"30px"}} >
        VAT number :{vatnumber}
        
        </label>
        </div>

        <br></br>

        <div>
        <label>Type of practice :{typeofpractice}</label>
         </div> 
        <br></br>

        <div>
        <label style={{marginRight:"",marginLeft:"0px",width:"234px",height:"30px"}}>
        Operating location address:{operatinglocation}
         
        </label>
        </div>
        <br></br>
        <div className="upload-btn2">
        <label style = {{height:"45px",width:"855px"}}>
        Uploads ( Proof of operating location, Company registration document, Proof of practice BHF number, VAT registration certificatee):{message19}<br></br></label>
        
        
        
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br> <div><h2>Banking Details</h2></div><br></br>
        <div>
        <label style={{marginRight:"10px",marginLeft:"0px",width:"328px",height:"30px"}}  >
        Account Name:{accountname}
        
        </label><br></br><br></br>
</div>
        <label style={{marginRight:"10px",marginLeft:"0px",width:"183px",height:"30px"}}>
        Account Number:{accountnumber}
       
        </label>
        

        <br></br><br></br>

        <div>
        <label style={{marginRight:"10px",marginLeft:"0px",width:"318px",height:"30px"}}>
        Branch Number:{branchnumber}
       
        </label><br></br><br></br></div>
        <label style={{marginRight:"",marginLeft:"0px",width:"238px",height:"30px"}}>
        Bank Name:{bankname}
        
        </label>
       
        

        <br></br><br></br>
        <div className="upload-btn">
        <label style = {{height:"45px",width:"855px"}}>
        Uploads (Bank Confirmation Letter):{message20}<br></br></label>
        

        
        
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div style = {{marginLeft:"680px",}}>
        {/* <Link to="/confirmation"> */}
          <button style={{height:"40px",width:"172px"}}>Save & Continue</button>
          {/* </Link> */}
        </div>
        </Accordion>
      </div>
    </div>
    </Layout>
   
  );
}

export default Register;
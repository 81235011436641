import React from "react";
import "../../PracticeInfo.css";
import Accordion from "../../shared/Accordion";

function PracticeInformation(){

 

  
  
   

    return (  
        
            
            <div style ={{height:"59vh"}} className="Register" >
                  <div className="header2">
          <br></br>
          <label style = {{fontSize:"40px"}}><strong></strong></label>
        </div>
      <div style={{width:"875px",height:"1011px"}}className="form-container">


<Accordion title="Personal Details">
      
      
        <div>
        
        <label style={{marginRight:"40px",marginLeft:"0px",width:"259px",height:"30px"}}>Name: 
        
        </label><br></br><br></br>
        </div>
        <div><label style={{marginRight:"40px",marginLeft:"0px",width:"259px",height:"30px"}}>Surname: 
        </label></div>
        
        

        <br></br>

        <div>
        <label style={{marginRight:"40px",marginLeft:"0px",width:"259px",height:"30px"}}>
        ID Number:
       
        </label><br></br><br></br>
</div>
        <label style={{marginRight:"",marginLeft:"0px",width:"272px",height:"30px"}}>
        Contact Number:
        
        </label>
        

        <br></br><br></br>

        <div>
        <label style={{marginRight:"",marginLeft:"0px",width:"308px",height:"30px"}}>
        Email:
        
        </label>
        <br></br><br></br>
        <label style={{marginRight:"",marginLeft:"0px",width:"",height:"30px"}}>
        HPCSA (MP) Number:
        
        </label>
        </div>

        <br></br>

        

        <div className="upload-btn">
        <label style = {{height:"45px",width:"855px"}}>
        Uploads (ID and Proof of residence ):
        <br></br></label>
        
        
        
        
        </div>
        <br></br>
        <br></br>
        <br></br>
        </Accordion>
        <Accordion title="Practice Details">
        <br></br>
        <div>
        <label style={{marginRight:"10px",marginLeft:"0px",width:"291px",height:"30px"}}>
        Practice BHF number:
       
        </label>
 </div><br></br>
        <label style={{marginRight:"",marginLeft:"0px",width:"197px",height:"30px"}} >
        Practice name:
        
        </label>
       

        <br></br><br></br>

        <div>
        <label style={{marginRight:"",marginLeft:"0px",width:"",height:"30px"}}>
        CIPC company registration number:
       
        </label>

       
        </div>

        <br></br>

        <div> <label style={{marginRight:"",marginLeft:"0px",width:"333px",height:"30px"}}>
        Contact Number:
        
        </label></div>

        <div>
          <br></br>
        <label style={{marginRight:"",marginLeft:"0px",width:"367px",height:"30px"}} >
        VAT number :
        
        </label>
        </div>

        <br></br>

        <div>
        <label>Type of practice :</label>
         </div> 
        <br></br>

        <div>
        <label style={{marginRight:"",marginLeft:"0px",width:"234px",height:"30px"}}>
        Operating location address:
         
        </label>
        </div>
        <br></br>
        <div className="upload-btn2">
        <label style = {{height:"45px",width:"855px"}}>
        Uploads ( Proof of operating location, Company registration document, Proof of practice BHF number, VAT registration certificatee):<br></br></label>
        
        
        
        </div>
        </Accordion>
   
        <Accordion title="Banking Details">
        <div>
        <label style={{marginRight:"10px",marginLeft:"0px",width:"328px",height:"30px"}}  >
        Account Name:
        
        </label><br></br><br></br>
</div>
        <label style={{marginRight:"10px",marginLeft:"0px",width:"183px",height:"30px"}}>
        Account Number:
       
        </label>
        

        <br></br><br></br>

        <div>
        <label style={{marginRight:"10px",marginLeft:"0px",width:"318px",height:"30px"}}>
        Branch Number:
       
        </label><br></br><br></br></div>
        <label style={{marginRight:"",marginLeft:"0px",width:"238px",height:"30px"}}>
        Bank Name:
        
        </label>
       
        

        <br></br><br></br>
        <div className="upload-btn">
        <label style = {{height:"45px",width:"855px"}}>
        Uploads (Bank Confirmation Letter):<br></br></label>
        
        
        
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div style = {{marginLeft:"680px",}}>
        
          <button style={{height:"40px",width:"150px"}}>Save & Continue</button>
          
        </div>
        </Accordion>



        </div>
            </div>
      
             
          
    );
}

export default PracticeInformation;
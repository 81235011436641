import React from "react";

interface AppointmentTypeProps {
  id: number;
  description: string;
  selected: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const AppointmentType = ({
  id,
  description,
  selected,
  onChange,
  disabled = false,
}: AppointmentTypeProps) => {
  const handleOnChange = () => {
    onChange();
  };
  return (
    <label className="appointment-type">
      {description}
      <input
        type="checkbox"
        onChange={handleOnChange}
        checked={selected}
        id={id.toString()}
        disabled={disabled}
      />
    </label>
  );
};

export default AppointmentType;

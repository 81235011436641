import React from "react";

import Doctor from "./Doctor";

interface SelectDoctorProps {
  onChange: (doctorId: number) => void;
  selectedDoctorId?: number;
  availableDoctors: { id: string; firstName: string; avatarUrl?: string }[];
}
const SelectDoctor = ({
  onChange,
  selectedDoctorId,
  availableDoctors,
}: SelectDoctorProps) => {
  const handleSetSelectedDoctor = (doctorId: number) => {
    onChange(doctorId);
  };

  return (
    <div className="select-doctor">
      <h1 className="u-margin-bottom-small">Select a doctor</h1>

      <div className="available-doctors">
        {availableDoctors.map((doctor: any) => {
          return (
            <Doctor
              key={doctor.id}
              title={`${doctor.firstName} ${doctor.lastName}`}
              id={doctor.id}
              imageUrl={doctor.avatarUrl}
              onClick={handleSetSelectedDoctor}
              selected={selectedDoctorId === doctor.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SelectDoctor;

import React from "react";

import { IDocument } from "../../../../types";
import Document from "./Document";

interface DocumentsProps {
  documents?: IDocument[];
}

const Documents = ({ documents }: DocumentsProps) => {
  return (
    <div className="documents">
      <h2>Documents</h2>
      <div className="u-margin-top-small">
        {!documents
          ? "No documents"
          : documents.map((document) => {
              return (
                <Document
                  id={document.id}
                  key={document.id}
                  fileName={document.fileName}
                  fileDirectory={document.fileDirectory}
                  uploadDate={document.uploadDate}
                />
              );
            })}
      </div>
    </div>
  );
};

export default Documents;

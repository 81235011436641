import React, { useEffect, useState } from "react";

import firebase from "./firebase";

interface ContextProps {
  currentUser: firebase.User | null;
  setCurrentUser: Function;
  loadingAuthState: boolean;
}

export const AuthContext = React.createContext<ContextProps>({
  currentUser: null,
  setCurrentUser: () => null,
  loadingAuthState: true,
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoadingAuthState(false);
    });
  }, []);
  return (
    <AuthContext.Provider
      value={{ currentUser, setCurrentUser, loadingAuthState }}
    >
      {children}
    </AuthContext.Provider>
  );
};

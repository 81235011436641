import { gql } from "@apollo/client";

export default gql`
  mutation createUserAndAppointment(
    $user: UserInput!
    $appointment: AppointmentInput!
  ) {
    createUserAndAppointment(user: $user, appointment: $appointment) {
      id
    }
  }
`;

/* eslint-disable react/jsx-props-no-spreading */

import React, { useContext } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { AuthContext } from "./Auth";

interface IPrivateRoute {
  component: React.ComponentType<RouteComponentProps<any>>;
  path: string;
  exact?: boolean;
}

const PrivateRoute = ({
  component: RouteComponent,
  path,
  exact = false,
}: IPrivateRoute) => {
  const { currentUser, loadingAuthState } = useContext(AuthContext);
  if (loadingAuthState) {
    return <></>;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={(routeProps) =>
        currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;

import React from "react";
import dayjs from "dayjs";
import { RiCloseCircleLine } from "react-icons/ri";
import {
  FiHelpCircle,
  FiMail,
  FiMapPin,
  FiPhone,
  FiUser,
} from "react-icons/fi";

interface AppointmentProps {
  title: string;
  id: number;
  appointmentLink: string;
  startDate: Date;
  endDate: Date;
  onClose?: () => void;
  onCancel?: (id: number) => void;
  patient: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    mobileNumber: string;
  };
  appointmentLocation?: string;
  appointmentType?: string;
}

const Appointment = ({
  title,
  id,
  appointmentLink,
  startDate,
  endDate,
  onClose,
  onCancel,
  patient,
  appointmentLocation,
  appointmentType,
}: AppointmentProps) => {
  return (
    <div className="appointment">
      <div className="title-block">
        <h2>{title}</h2>
        {onClose && (
          <button onClick={onClose} type="button">
            <RiCloseCircleLine size="2rem" />
          </button>
        )}
      </div>
      <span className="date">
        {dayjs(startDate).format("dddd[,] MMMM DD HH:mm ")} -{" "}
        {dayjs(endDate).format("HH:mm")}
      </span>
      <div className="patient-details-block u-margin-top-small">
        <div>
          <FiUser /> {patient.firstName} {patient.lastName}
        </div>
        <div>
          <FiMail />{" "}
          <a href={`mailTo:${patient.emailAddress}`}>{patient.emailAddress}</a>
        </div>
        <div>
          <FiPhone /> {patient.mobileNumber}
        </div>
        <div>
          <FiHelpCircle /> Consultation type: {appointmentType}
        </div>

        {appointmentLocation && (
          <div>
            <FiMapPin />{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${appointmentLocation}`}
            >
              {appointmentLocation}
            </a>
          </div>
        )}
      </div>
      <div className="links-block u-margin-top-small">
        <div>
          Zoom link:{" "}
          <a target="_blank" href={appointmentLink} rel="noopener noreferrer">
            {appointmentLink || "No Zoom link found"}
          </a>
        </div>
        <div />
      </div>
      {onCancel && (
        <div>
          <button
            type="button"
            onClick={() => onCancel(id)}
            className="cancel-appointment-button u-margin-top-medium"
          >
            Cancel appointment{" "}
          </button>
        </div>
      )}
    </div>
  );
};

export default Appointment;

import React from "react";

import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";

import { Register, ResetPassword, test } from "./components/auth";
import { LoginOrRegister, Services, Booking, Landing } from "./pages";
import Dashboard from "./pages/doctor/Dashboard";
import Confirmation from "./components/auth/Confirmation";
import MorePractice from "./components/auth/MorePractice";

function App() {
  return (
    <AuthProvider>
      <ToastContainer />
      <Router>
        <Route path="/register" component={Register} />
        <Route path="/test" component={test} />
        <Route path="/confirmation" component={Confirmation} />
        <Route path="/MorePractice" component={MorePractice} />
        <Route path="/booking" component={Booking} />
        <Route path="/landing" component={Landing} />
        <Route path="/services" component={Services} />
        <Route exact path="/login" component={LoginOrRegister} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <PrivateRoute path="/admin" component={Dashboard} />

        <Route exact path="/" render={() => <Redirect to="/landing" />} />
      </Router>
    </AuthProvider>
  );
}

export default App;

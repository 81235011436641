import React from "react";

import { ClipLoader } from "react-spinners";

const Loader = () => {
  return (
    <>
      <ClipLoader color="#ef6b8a" />
    </>
  );
};

export default Loader;

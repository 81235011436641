import React from "react";
import Accordion from "../../shared/Accordion";



function PendingApps(){
    return (  
        
            
            <div style ={{height:"59vh"}} className="Register" >
      {/* <div className="progressbar">
        <div style={{width: page === 0 ? "20%" : page === 1 ? "40%" :  page === 2 ? "60%" :page === 3 ? "80%" :"100%"}}></div>
      </div> */}
             
          <br></br>
          
          <div style={{width:"" ,height:""}} className="form-container">
          <Accordion title="Pending Applications">       
          <div style={{marginLeft:"644px"}}><button style={{height:"40px",width:"100px"}}>Accept</button> <button style={{height:"40px",width:"100px"}} >Reject</button></div>
          </Accordion>
        </div>
        </div>
        
        
    );
}

export default PendingApps;
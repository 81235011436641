import React from "react";

interface RegisterButtonProps {
  className?: string;
  text?: string;
}
const RegisterButton = ({ className, text = "Register as a healthcare provider" }: RegisterButtonProps) => (
  <a href="/register" className={`register-btn ${className}`} type="button">
    
    {text}
  </a>
);

export default RegisterButton;
import React from "react";
import { useHistory, useParams } from "react-router";
import { FiArrowLeft, FiMail, FiPhone, FiUser } from "react-icons/fi";
import { useQuery } from "@apollo/client";

import Documents from "./Documents";

import PatientAppointments from "./appointments/PatientAppointments";
import fetchPatient from "../../../../queries/fetchPatient";
import "react-toastify/dist/ReactToastify.css";
import { IAppointment, IDocument } from "../../../../types";

const Patient = () => {
  const { patientId } = useParams();

  const { loading, error, data } = useQuery(fetchPatient, {
    variables: { id: Number(patientId) },
  });

  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <p>ERROR</p>;
  if (!data || !data.patient) return <p>No patient found</p>;

  // const { Patient: patient } = data;
  const { patient } = data;
  const documents = patient.patientFiles as Array<IDocument>;
  const appointments = patient.appointments as Array<IAppointment>;

  return (
    <div className="patient u-margin-top-medium">
      <div className="left-hand-side">
        <button
          className="back-btn u-margin-bottom-small"
          type="button"
          onClick={goBack}
        >
          <FiArrowLeft />
        </button>
        <span className="page-heading">
          <FiUser /> {patient.firstName} {patient.lastName}
        </span>
        <div className="patient-info">
          <div className="detail-card">
            <FiMail className="detail-card-icon" />{" "}
            <a href={`mailTo:${patient.emailAddress}`}>
              {patient.emailAddress}
            </a>
          </div>
          <div className="detail-card">
            <FiPhone className="detail-card-icon" /> {patient.mobileNumber}
          </div>
        </div>
        <div className="u-margin-top-small">
          <Documents documents={documents} />
        </div>
      </div>
      <div className="u-margin-top-medium right-hand-side">
        {appointments && <PatientAppointments appointments={appointments} />}
      </div>
    </div>
  );
};
export default Patient;

import { gql } from "@apollo/client";

export default gql`
  query fetchUserByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      firstName
      lastName
      emailAddress
    }
  }
`;

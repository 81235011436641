import { gql } from "@apollo/client";

export default gql`
  query fetchAppointmentTypes {
    appointmentTypes {
      id
      description
    }
  }
`;

import React, { useRef } from "react";

interface TimeSlotProps {
  minute: number;
  hour: number;
  selected: boolean;
  onClick: (hour: number, minute: number, doctors: any) => void;
  addLeadingZero?: boolean;
  doctors: [];
}

const TimeSlot = ({
  minute,
  hour,
  onClick,
  selected,
  addLeadingZero = true,
  doctors,
}: TimeSlotProps) => {
  const handleOnClick = () => {
    onClick(hour, minute, doctors);
  };
  const inputRef = useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (inputRef.current && selected) {
      inputRef.current.scrollIntoView();
    }
  }, [selected]);

  return (
    <button
      className={`time-slot ${selected ? " active" : ""}`}
      type="button"
      onClick={handleOnClick}
      ref={inputRef}
    >
      {addLeadingZero ? (
        <span>
          {`0${hour}`.slice(-2)}:{`0${minute}`.slice(-2)}
        </span>
      ) : (
        <span>
          {hour}:{minute}
        </span>
      )}
    </button>
  );
};

export default TimeSlot;

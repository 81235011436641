import React from "react";
import Layout from "../layout/Layout";

function test() {
  return (
    <Layout>
      <div style={{ height: "59vh" }} className="Register2">
        {/* <div className="progressbar">
        <div style={{width: page === 0 ? "20%" : page === 1 ? "40%" :  page === 2 ? "60%" :page === 3 ? "80%" :"100%"}}></div>
      </div> */}

        <br></br>

        <div
          style={{ width: "550px", height: "400px" }}
          className="form-container"
        >
         <form>
         <input style={{marginRight:"40px",marginLeft:"10px",width:"259px",height:"30px"}} type="text"
          id="name"
          name="name"
         />
         <input style={{marginRight:"40px",marginLeft:"10px",width:"259px",height:"30px"}} type="submit"
          id="submit"
          name="submit"
         />
         </form>
        </div>
      </div>
    </Layout>
  );
}

export default test;

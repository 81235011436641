import React, { useState } from "react";
import FullCalendar, { EventClickArg } from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { toast } from "react-toastify";

import { gql, useMutation, useQuery } from "@apollo/client";

import dayjs from "dayjs";

import Appointment from "./Appointment";
import {
  cancelAppointment as cancelAppointmentMutation,
  createDoctorUnavailable,
  fetchDoctorAppointmentsAndUnvailability,
} from "../../../../queries";
import { AppointmentStatusEnum, SlotTypeEnum } from "../../../../enums";
import ReversedSlotControl from "./ReversedSlotControl";
import deleteDoctorUnavailable from "../../../../queries/deleteDoctorUnavailable";
import Loader from "../../../shared/Loader";
import StandardError from "../../../shared/StandardError";

const Appointments = () => {
  const { loading, error, data } = useQuery(
    fetchDoctorAppointmentsAndUnvailability,
  );

  const [selectedSlot, setSelectedSlot] = useState<any>();
  const [slotSelection, setSlotSelection] = useState<any>();
  const [cancelAppointment] = useMutation(cancelAppointmentMutation);
  const [createUnavailableSlot] = useMutation(createDoctorUnavailable);
  const [deleteUnavailableSlot] = useMutation(deleteDoctorUnavailable);

  const hasDateOverlap = (
    startDateOne: Date,
    startDateTwo: Date,
    endDateOne: Date,
    endDateTwo: Date,
  ) => {
    if (startDateOne < endDateTwo && startDateTwo < endDateOne) {
      return true;
    }
    return false;
  };
  const handleSetSelectedAppointment = (
    appointment: EventClickArg | undefined,
  ) => {
    if (appointment === undefined) {
      setSelectedSlot(undefined);
    } else if (
      appointment?.event.extendedProps.type === SlotTypeEnum.Unavailable
    ) {
      setSelectedSlot({
        id: appointment.event.id,
        type: appointment.event.extendedProps.type,
      });
    } else {
      setSelectedSlot({
        title: appointment.event.title,
        appointmentLink: appointment.event.extendedProps.appointmentLink,
        appointmentLocation:
          appointment.event.extendedProps.appointmentLocation,
        appointmentType: appointment.event.extendedProps.appointmentType,
        appointmentId: appointment.event.extendedProps.appointmentId,
        startDate: appointment.event.start,
        endDate: appointment.event.end,
        type: appointment.event.extendedProps.type,
        patient: {
          firstName: appointment.event.extendedProps.patient.firstName,
          lastName: appointment.event.extendedProps.patient.lastName,
          mobileNumber: appointment.event.extendedProps.patient.mobileNumber,
          emailAddress: appointment.event.extendedProps.patient.emailAddress,
        },
      });
    }
  };

  const handleCancelAppointment = () => {
    cancelAppointment({
      variables: {
        appointmentId: selectedSlot.appointmentId,
        zoomMeetingId: selectedSlot.appointmentLink.substr(
          selectedSlot.appointmentLink.lastIndexOf("/") + 1,
        ),
      },
      update(cache) {
        cache.modify({
          fields: {
            appointments(existingAppointmentsRef, { readField }) {
              return existingAppointmentsRef.filter(
                (appointmentRef: any) =>
                  selectedSlot.appointmentId !==
                  readField("id", appointmentRef),
              );
            },
          },
        });
      },
    })
      .then(() => {
        setSelectedSlot(undefined);
        toast.success(`Appointment cancelled`);
      })
      .catch(() => {
        toast.error(`Error cancelling appointment`);
      });
  };

  const handleDeleteDoctorUnavailability = () => {
    deleteUnavailableSlot({
      variables: {
        doctorUnavailableId: selectedSlot.id,
      },
      update(cache) {
        cache.modify({
          fields: {
            doctorUnavailability(unavailabilityRef, { readField }) {
              return unavailabilityRef.filter(
                (slotRef: any) => selectedSlot.id !== readField("id", slotRef),
              );
            },
          },
        });
      },
    })
      .then(() => {
        setSelectedSlot(undefined);
        toast.success(`Reserved slot removed`);
      })
      .catch(() => {
        toast.error(`Error removing reversed slot`);
      });
  };
  const handleSlotSelect = (slot: any) => {
    setSlotSelection(slot);
  };

  if (loading) return <Loader />;
  if (error) return <StandardError />;

  const { appointments, doctorUnavailability } = data;

  const mapAppointments = (appointmentsToMap: any) => {
    return appointmentsToMap
      .map((appointment: any) => {
        return {
          start: appointment.startDate,
          end: appointment.endDate,
          title: `Appointment with ${appointment.patient.firstName}`,
          appointmentId: appointment.id,
          appointmentLink: appointment.appointmentLink
            ? appointment.appointmentLink.url
            : "",
          appointmentStatus: appointment.appointmentStatus,
          appointmentType: appointment.appointmentType.description,
          appointmentLocation: appointment.appointmentLocation
            ? appointment.appointmentLocation.location
            : "",
          patient: {
            firstName: appointment.patient.firstName,
            lastName: appointment.patient.lastName,
            emailAddress: appointment.patient.emailAddress,
            mobileNumber: appointment.patient.mobileNumber,
          },
        };
      })
      .filter((appointment: any) => {
        return (
          Number(appointment.appointmentStatus.id) !==
          AppointmentStatusEnum.CANCELLED
        );
      });
  };

  const blockedSlots = doctorUnavailability.map((unavailable: any) => {
    return {
      start: unavailable.startDate,
      end: unavailable.endDate,
      title: "Reserved slot",
      type: SlotTypeEnum.Unavailable,
      backgroundColor: "#000",
      id: unavailable.id,
    };
  });
  const modifiedAppointments = mapAppointments(appointments);

  const handleBlockOutSlotSelection = () => {
    const hasOverlapWithAppointments = modifiedAppointments.some(
      (modifiedAppointment: any) => {
        return hasDateOverlap(
          dayjs(modifiedAppointment.start).toDate(),
          slotSelection.start,
          dayjs(modifiedAppointment.end).toDate(),
          slotSelection.end,
        );
      },
    );
    if (hasOverlapWithAppointments) {
      toast.error("Unable to block out slots with appointments");
    } else {
      createUnavailableSlot({
        variables: {
          input: {
            startDate: slotSelection.start,
            endDate: slotSelection.end,
          },
        },

        update(cache, newUnavailability) {
          cache.modify({
            fields: {
              doctorUnavailability(existingUnavailability = []) {
                const newAvailabilityRef = cache.writeFragment({
                  data: newUnavailability.data.createDoctorUnavailable,
                  fragment: gql`
                    fragment NewUnavailability on DoctorUnavailable {
                      id
                      startDate
                      endDate
                    }
                  `,
                });
                return [...existingUnavailability, newAvailabilityRef];
              },
            },
          });
        },
      });
      toast.success("Selection blocked out");
    }
  };

  return (
    <div className="appointments u-margin-top-small">
      <FullCalendar
        handleWindowResize={false}
        plugins={[timeGridPlugin, interactionPlugin]}
        customButtons={{
          blockOutSlotButton: {
            text: "Block selection",
            click() {
              handleBlockOutSlotSelection();
            },
          },
        }}
        headerToolbar={{
          right: "timeGridWeek,timeGridDay",
          left: "prev,next,blockOutSlotButton today",
          center: "title",
        }}
        initialView="timeGridDay"
        slotMinTime="08:00:00"
        slotMaxTime="17:00:00"
        allDaySlot={false}
        selectable
        expandRows
        contentHeight="auto"
        firstDay={1}
        select={handleSlotSelect}
        events={modifiedAppointments.concat(blockedSlots)}
        eventClick={(appointment) => {
          handleSetSelectedAppointment(appointment);
        }}
      />
      {selectedSlot && selectedSlot.type !== SlotTypeEnum.Unavailable && (
        <Appointment
          appointmentLink={selectedSlot.appointmentLink}
          id={selectedSlot.id}
          title={selectedSlot.title}
          startDate={selectedSlot.startDate}
          endDate={selectedSlot.endDate}
          onClose={() => handleSetSelectedAppointment(undefined)}
          onCancel={handleCancelAppointment}
          patient={selectedSlot.patient}
          appointmentLocation={selectedSlot.appointmentLocation}
          appointmentType={selectedSlot.appointmentType}
        />
      )}
      {selectedSlot && selectedSlot.type === SlotTypeEnum.Unavailable && (
        <ReversedSlotControl
          onRemoveReversedSlot={handleDeleteDoctorUnavailability}
          onClose={() => handleSetSelectedAppointment(undefined)}
        />
      )}
    </div>
  );
};

export default Appointments;

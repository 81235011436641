import React from "react";
import LandingBanner from "../components/landing/LandingBanner";
import OldHomePage from "../components/landing/OldHomePage";

import Layout from "../components/layout/Layout";

const Landing = () => {
  return (
    <Layout showNewsletterSignup>
      <LandingBanner />
      <OldHomePage />
    </Layout>
  );
};

export default Landing;

import React from "react";
import { useQuery } from "@apollo/client";
import { Route, Switch, useRouteMatch } from "react-router";

import Patient from "./Patient";
import PatientTable from "./patient_table/PatientTable";
import fetchPatients from "../../../../queries/fetchPatients";

const Patients = () => {
  const { loading, error, data } = useQuery(fetchPatients);
  const { path } = useRouteMatch();

  if (loading) return <div>Loading...</div>;
  if (error) return <p>ERROR</p>;
  if (!data) return <p>No patients found</p>;

  /* Accidentally discoverd this method of routing for more granular control of routes.
     Not sure if this is a bit of a code smell, having routing deeply nested in the app. 
  */

  return (
    <Switch>
      <Route exact path={path}>
        <div className="patients u-margin-top-small">
          <h1 className="u-margin-bottom-small">Patients</h1>
          <PatientTable data={data.patients} />
        </div>
      </Route>
      <Route exact path={`${path}/:patientId`}>
        <Patient />
      </Route>
    </Switch>
  );
};
export default Patients;

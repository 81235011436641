import { gql } from "@apollo/client";

export default gql`
  mutation deleteDoctorAppointmentType($input: DoctorAppointmentTypeInput!) {
    deleteDoctorAppointmentType(input: $input) {
      doctor {
        id
        firstName
        lastName
        emailAddress
        mobileNumber
        doctorAppointmentTypes {
          appointmentType {
            id
          }
        }
      }
    }
  }
`;

import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router";
import Appointments from "../../components/doctor/dashboard/appointments/Appointments";
import ManageAppointmentTypes from "../../components/doctor/dashboard/appointments/ManageAppointmentTypes";

import Patients from "../../components/doctor/dashboard/patients/Patients";
import PendingApps from "../../components/doctor/PracticeInformation/PendingApps";
import ManagePractice from "../../components/doctor/PracticeInformation/ManagePractice";
import PracticeInformation from "../../components/doctor/PracticeInformation/PracticeInformation";
import Sidebar from "../../components/layout/doctor/Sidebar";

import {
  APPOINTMENTS,
  MANAGEPRACTICE,
  MANAGE_APPOINTMENT_TYPES,
  PATIENTS,
  PENDINGAPPS,
  PRACTICEINFORMATION,
} from "../../constants";

export function DashboardAction() {
  const { actionId } = useParams();

  switch (actionId) {
    case APPOINTMENTS:
      return <Appointments />;
    case MANAGE_APPOINTMENT_TYPES:
      return <ManageAppointmentTypes />;
    case PATIENTS:
      return <Patients />;
    case PRACTICEINFORMATION:
      return <PracticeInformation />;
    case MANAGEPRACTICE:
      return <ManagePractice />;
    case PENDINGAPPS:
      return <PendingApps />;
    default:
      return null;
  }
}
const Dashboard = () => {
  const { path } = useRouteMatch();

  return (
    <div className="dashboard">
      <Sidebar />
      <Switch>
        <Route path={`${path}/:actionId`}>
          <DashboardAction />
        </Route>
        <Route exact path={path}>
          <h3>Welcome back!</h3>
        </Route>
      </Switch>
    </div>
  );
};

export default Dashboard;

import React from "react";
import { BiCheckCircle } from "react-icons/bi";
import { useHistory } from "react-router";

const BookingConfirmation = () => {
  const history = useHistory();
  const handleResetBooking = () => {
    history.replace("/booking");
  };

  return (
    <div className="booking-confirmed">
      <h2>Booking confirmed</h2>
      <BiCheckCircle size="4.5rem" />

      <button
        onClick={handleResetBooking}
        className="new-appointment-button"
        type="button"
      >
        Create another appointment
      </button>
    </div>
  );
};

export default BookingConfirmation;

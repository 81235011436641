import React from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { FaLaptopMedical, FaStethoscope } from "react-icons/fa";

import { IoBandageOutline } from "react-icons/io5";

import LogoLarge from "../../images/landing/logo-large.png";

const LandingBanner = () => {
  const generateIcons = () => {
    const possibleIcons = [
      <svg
        className="bandage-svg"
        data-name="Layer-1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <IoBandageOutline className="animation bandage-animation bandage-top-layer " />
        <IoBandageOutline className="animation bandage-animation bandage-bottom-layer " />
      </svg>,

      <svg
        className="stethoscope-svg"
        data-name="Layer-2"
        xmlns="http://www.w3.org/2000/svg"
      >
        <FaStethoscope className="animation stethoscope-animation stethoscope-bottom-layer" />
        <FaStethoscope className="animation stethoscope-animation  stethoscope-top-layer" />
      </svg>,
      <svg
        className="computer-svg"
        data-name="Layer-1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <FaLaptopMedical className="animation computer-animation computer-top-layer " />
        <FaLaptopMedical className="animation computer-animation computer-bottom-layer " />
      </svg>,
      <svg className="heart-svg" xmlns="http://www.w3.org/2000/svg">
        <AiOutlineHeart className="animation heart-animation" />
      </svg>,
    ];
    const generatedIcons = [];
    const isMobileDisplay = window.matchMedia("(max-width: 767px)").matches;
    const loopAmount = isMobileDisplay ? 240 : 340;
    for (let i = 0; i < loopAmount; i++) {
      generatedIcons.push(
        possibleIcons[Math.floor(Math.random() * possibleIcons.length)],
      );
    }
    return generatedIcons;
  };
  return (
    <div className="landing-banner">
      <div className="wrapper">
        <div className="content">
          <div className="image-wrapper">
            <img src={LogoLarge} alt="prime medical logo" />
          </div>
        </div>
        <div className="background">
          <div className="grid-container">{generateIcons()}</div>
        </div>
      </div>
    </div>
  );
};

export default LandingBanner;

import { useLazyQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import { FiArrowLeftCircle } from "react-icons/fi";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { AppointmentTypeEnum, InstoreServiceTypeEnum } from "../../../enums";
import {
  createUserAndAppointment as createUserAndAppointmentMutation,
  fetchAvailableAppointments,
} from "../../../queries";
import { IPatientDetails, IService } from "../../../types";
import Loader from "../../shared/Loader";
import StandardError from "../../shared/StandardError";
import CapturePatientDetails from "./appointment/CapturePatientDetails";
import DatePicker from "./appointment/DatePicker";

import SelectAppointmentType from "./appointment/SelectAppointmentType";
import SelectDoctor from "./appointment/SelectDoctor";
import SelectDoctorLocation from "./appointment/SelectDoctorLocation";
import SelectService from "./appointment/services/SelectService";
import TimeSlotPicker from "./appointment/TimeSlotPicker";
import BookingConfirmation from "./BookingConfirmation";
import FormControl from "./FormControl";

interface Form {
  startDate: Date | undefined;
  startTime: { hour: number; minute: number } | undefined;
  doctorId: undefined | number;
  locationId: undefined | number;
  instoreVirtualConsultationType: InstoreServiceTypeEnum | undefined;
  selectedStoreId: number | undefined;
  patientDetails: IPatientDetails;
  appointmentTypeId: undefined | number;
  selectedServices: IService[];
  appointmentCost: number;
  availableTimeSlots: { startTime: Date; doctors: [] }[] | undefined;
}
const initialiseForm = () =>
  ({
    startDate: undefined,
    startTime: undefined,
    availableTimeSlots: undefined,
    doctorId: undefined,
    appointmentTypeId: undefined,
    locationId: undefined,
    instoreVirtualConsultationType: undefined,
    selectedStoreId: undefined,
    patientDetails: {
      firstName: "",
      lastName: "",
      idNumber: "",
      cellNumber: "",
      emailAddress: "",
      password: "",
    },
    selectedServices: [] as IService[],
    appointmentCost: 0,
  } as Form);
const AppointmentForm = () => {
  const history = useHistory();

  const { path } = useRouteMatch();

  const [appointment, setAppointment] = useState(initialiseForm());
  const isMobileDisplay = window.matchMedia("(max-width: 767px)").matches;
  const [showTimePicker, setShowTimePicker] = useState(false);

  const [
    availableDoctorsForSelectedDate,
    setAvailableDoctorsForSelectedDate,
  ] = useState<undefined | any>();

  useEffect(() => {
    const appointmentCost = appointment.selectedServices.reduce(
      (accu, ele) => accu + ele.cost,
      0,
    );
    setAppointment({ ...appointment, appointmentCost });
  }, [appointment.selectedServices]);

  useEffect(() => {
    setAppointment({ ...appointment, locationId: undefined });
    setShowTimePicker(false);
  }, [appointment.appointmentTypeId]);

  useEffect(() => {
    setAppointment({ ...appointment, selectedServices: [] });
  }, [appointment.doctorId]);

  const [
    getAvailableAppointments,
    { loading, data: availableAppointments, error },
  ] = useLazyQuery(fetchAvailableAppointments, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (appointment.startDate) {
      const availableSlots = (() => {
        return availableAppointments.availableAppointments
          .filter((slot: any) => {
            if (!slot.doctors || slot.doctors.length === 0) {
              return null;
            }

            if (
              dayjs(slot.startTime).date() ===
                dayjs(appointment.startDate).date() &&
              dayjs(slot.startTime).month() ===
                dayjs(appointment.startDate).month()
            ) {
              if (
                dayjs(slot.startTime).hour() > dayjs().hour() &&
                dayjs(appointment.startDate).date() === dayjs().date()
              ) {
                return slot;
              }

              if (dayjs(appointment.startDate).isAfter(dayjs())) {
                return slot;
              }
            }
            return null;
          })
          .map((appointmentSlot: any) => {
            return {
              startTime: new Date(appointmentSlot.startTime),
              doctors: appointmentSlot.doctors,
            };
          });
      })();
      setAppointment({ ...appointment, availableTimeSlots: availableSlots });
      setShowTimePicker(true);
    } else {
      setShowTimePicker(false);
    }
  }, [appointment.startDate]);

  const [createUserAndAppointment, { loading: submitLoading }] = useMutation(
    createUserAndAppointmentMutation,
  );

  const handleSetDoctorLocationId = (locationId: any) => {
    if (appointment.startDate) {
      setAppointment({
        ...appointment,
        locationId,
        startDate: undefined,
        startTime: undefined,
        doctorId: undefined,
        selectedServices: [],
      });
    } else {
      setAppointment({ ...appointment, locationId });
    }
  };
  const [openCategory, setOpenCategory] = useState<undefined | number>();

  const handleSetSelectedServices = (selectedServices: IService[]) => {
    setAppointment({ ...appointment, selectedServices });
  };

  const handleSetOpenCategory = (categoryId: number) => {
    if (openCategory === categoryId) {
      setOpenCategory(undefined);
    } else {
      setOpenCategory(categoryId);
    }
  };

  const handleSetAppointmentType = (appointmentTypeId: number) => {
    setAppointment({
      ...appointment,
      appointmentTypeId,
      doctorId: undefined,
      startDate: undefined,
      startTime: undefined,
      selectedServices: [],
    });
  };
  const handleSetAppointmentDate = (
    appointmentDate: Date,
    availableDoctors: [],
  ) => {
    setAppointment({
      ...appointment,
      startDate: appointmentDate,
      startTime: undefined,
      selectedServices: [],
    });

    setAvailableDoctorsForSelectedDate(availableDoctors);
  };

  const handleChangeTime = (
    selectedTime: { hour: number; minute: number },
    availableDoctors: any,
  ) => {
    setAppointment({ ...appointment, startTime: selectedTime });
    setAvailableDoctorsForSelectedDate(availableDoctors);
  };

  const handleGetAvailableAppointmentsToSelectDate = () => {
    if (
      appointment.locationId &&
      appointment.appointmentTypeId !== AppointmentTypeEnum.Virtual
    ) {
      getAvailableAppointments({
        variables: {
          locationId: Number(appointment.locationId),
          appointmentTypeId: Number(appointment.appointmentTypeId),
        },
      });
    } else {
      getAvailableAppointments({
        variables: {
          appointmentTypeId: Number(appointment.appointmentTypeId),
        },
      });
    }
    history.push(`${path}/select-date`);
  };

  const handleSetDoctorId = (selectedDoctorId: number) => {
    setAppointment({ ...appointment, doctorId: selectedDoctorId });
  };

  const handleSubmitForm = async (
    submittedPatientDetails: IPatientDetails,
    token?: string,
  ) => {
    try {
      const {
        firstName,
        lastName,
        emailAddress,
        cellNumber,
        password,
        billingAddress,
        physicalAddress,
        appointmentLocation,
        idNumber,
        newsletter,
      } = submittedPatientDetails;
      let medicalAid;
      if (
        submittedPatientDetails.medicalAidName &&
        submittedPatientDetails.medicalAidNumber &&
        submittedPatientDetails.medicalAidPlan
      ) {
        medicalAid = {
          provider: submittedPatientDetails.medicalAidName,
          number: submittedPatientDetails.medicalAidNumber,
          plan: submittedPatientDetails.medicalAidPlan,
        };
      }
      Cookies.set("viewedNewsletterSignup", "true", { expires: 60 });

      await createUserAndAppointment({
        variables: {
          user: {
            firstName,
            lastName,
            emailAddress,
            mobileNumber: cellNumber,
            password,
            billingAddress,
            physicalAddress,
            idNumber,
            medicalAid,
            addToNewsletterMailingList: newsletter,
          },
          appointment: {
            doctorUserId: Number(appointment.doctorId),
            patientUserId: 0,
            startDate: dayjs(appointment.startDate)
              .hour(appointment.startTime!.hour)
              .minute(appointment.startTime!.minute)
              .toISOString(),
            endDate: dayjs(appointment.startDate)
              .hour(appointment.startTime!.hour)
              .minute(appointment.startTime!.minute)
              .add(30, "minute")
              .toISOString(),
            appointmentTypeId: appointment.appointmentTypeId,
            appointmentLocation,
            services: appointment.selectedServices.map((service) => {
              return Number(service.id);
            }),
            payment:
              !medicalAid?.number && token
                ? {
                    amount: Number(appointment.appointmentCost.toFixed(2)),
                    token,
                  }
                : {},
          },
        },
      });
      setAppointment(initialiseForm());
      history.replace("/booking/complete");
    } catch (serverError) {
      setAppointment({
        ...appointment,
        patientDetails: submittedPatientDetails,
      });

      if (serverError.message === "Payment failed!") {
        toast.error(`Transaction failed. Please try again`);
      } else if (serverError.message === "Incorrect service cost") {
        toast.error(`Service cost incorrect, please try again`);
      } else {
        toast.error(`Error creating appointment. Please try again`);
      }
    }
  };

  const FormRouting = () => {
    const { actionId } = useParams();

    // Not sure if we should have separate component for navigation.
    // Below doesn't follow DRY but the buttons are all different in their routing rules
    switch (actionId) {
      default:
        return <Redirect to="/booking" />;
      case "location":
        if (!appointment.appointmentTypeId) {
          return <Redirect to="/booking" />;
        }
        return (
          <FormControl
            onNextButtonClick={handleGetAvailableAppointmentsToSelectDate}
            nextButtonDisabled={!appointment.locationId}
          >
            <SelectDoctorLocation
              selectedDoctorLocationId={appointment.locationId}
              onChange={handleSetDoctorLocationId}
            />
          </FormControl>
        );
      case "select-date":
        if (
          !appointment.locationId &&
          appointment.appointmentTypeId !== AppointmentTypeEnum.Virtual
        ) {
          return <Redirect to="/booking/location" />;
        }
        if (loading) return <Loader />;
        if (error) {
          return <StandardError />;
        }
        if (!availableAppointments && !loading)
          return <p>No available time slots</p>;

        return (
          <FormControl
            nextButtonDisabled={!appointment.startTime}
            onNextButtonClick={() => history.push("select-doctor")}
            onBackButtonClick={
              isMobileDisplay && showTimePicker
                ? () => setShowTimePicker(false)
                : undefined
            }
          >
            <h1 className="u-text-center">Select a date and time</h1>
            {appointment.startDate && appointment.startTime && (
              <h2 className="selected-date">
                {dayjs(appointment.startDate)
                  .hour(appointment.startTime.hour)
                  .minute(appointment.startTime.minute)
                  .format("ddd DD MMMM[,] HH:mm")}
              </h2>
            )}
            {showTimePicker && isMobileDisplay && (
              <button
                onClick={() => setShowTimePicker(false)}
                type="button"
                className="show-date-button"
              >
                <FiArrowLeftCircle size="3rem" />
              </button>
            )}

            <div className="date-time-picker">
              <div className={`${showTimePicker ? "u-hidden-mobile" : ""} `}>
                <DatePicker
                  onDateSelect={handleSetAppointmentDate}
                  selectedDate={appointment.startDate}
                />
              </div>

              {showTimePicker && (
                <TimeSlotPicker
                  onChangeTime={handleChangeTime}
                  selectedTime={appointment.startTime}
                  possibleTimes={appointment.availableTimeSlots}
                />
              )}
            </div>
          </FormControl>
        );
      case "select-doctor": {
        if (!appointment.startTime || !availableDoctorsForSelectedDate) {
          return <Redirect to="/booking/select-date" />;
        }
        return (
          <FormControl
            nextButtonDisabled={!appointment.doctorId}
            onNextButtonClick={() => history.push("services")}
          >
            <SelectDoctor
              availableDoctors={availableDoctorsForSelectedDate}
              onChange={handleSetDoctorId}
              selectedDoctorId={appointment.doctorId}
            />
          </FormControl>
        );
      }
      case "services": {
        if (!appointment.doctorId) {
          return <Redirect to="/booking/select-doctor" />;
        }
        return (
          <FormControl
            nextButtonDisabled={appointment.selectedServices.length < 1}
            onNextButtonClick={() => history.push("capture-details")}
          >
            <SelectService
              onChangeSelectedServices={handleSetSelectedServices}
              selectedServices={appointment.selectedServices}
              onChangeOpenCategory={handleSetOpenCategory}
              openCategory={openCategory}
              selectedAppointmentTypeId={appointment.appointmentTypeId}
            />
          </FormControl>
        );
      }

      case "capture-details":
        if (appointment.selectedServices.length < 1) {
          return <Redirect to="/booking/services" />;
        }
        if (submitLoading) {
          return <Loader />;
        }

        return (
          <FormControl nextButtonVisible={false}>
            <CapturePatientDetails
              userDetails={appointment.patientDetails}
              onFormSubmit={handleSubmitForm}
              requireConsultationAddress={
                appointment.appointmentTypeId === AppointmentTypeEnum.Home
              }
              appointmentCost={appointment.appointmentCost}
            />
          </FormControl>
        );
      case "complete":
        return <BookingConfirmation />;
    }
  };

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="appointment-form u-margin-top-medium">
        <Switch>
          <Route exact path={path}>
            <FormControl
              nextButtonDisabled={!appointment.appointmentTypeId}
              onNextButtonClick={() => {
                if (
                  appointment.appointmentTypeId === AppointmentTypeEnum.Virtual
                ) {
                  handleGetAvailableAppointmentsToSelectDate();
                } else {
                  history.push(`${path}/location`);
                }
              }}
              backButtonVisible={false}
            >
              <SelectAppointmentType
                onChange={handleSetAppointmentType}
                selectedAppointmentTypeId={appointment.appointmentTypeId}
              />
            </FormControl>
          </Route>
          <Route exact path={`${path}/:actionId`}>
            <FormRouting />
          </Route>
        </Switch>
      </div>
    </motion.div>
  );
};

export default AppointmentForm;

import React from "react";
import { FaRegCalendarAlt } from "react-icons/fa";

interface BookButtonProps {
  className?: string;
  text?: string;
}
const BookButton = ({ className, text = "Book Now" }: BookButtonProps) => (
  <a href="/booking" className={`book-btn ${className}`} type="button">
    <FaRegCalendarAlt />
    {text}
  </a>
);

export default BookButton;

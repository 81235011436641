import { gql } from "@apollo/client";

export default gql`
  query GetPatients {
    patients {
      id
      firstName
      lastName
      emailAddress
      mobileNumber
    }
  }
`;

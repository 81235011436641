const firebaseConfig = {
  apiKey: "AIzaSyB08YRdkuAMZnz-QymtBtMmAXISur0sSso",
  authDomain: "prime-med-dev.firebaseapp.com",
  databaseURL: "https://prime-med-dev.firebaseio.com",
  projectId: "prime-med-dev",
  storageBucket: "prime-med-dev.appspot.com",
  messagingSenderId: "685604734326",
  appId: "1:685604734326:web:3a73360bf2857364b9e657",
};

export default firebaseConfig;

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import firebase from "./firebase";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./styles/main.scss";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn:
      "https://4eec4123b02f4570bb113c77c16e7b02@o230077.ingest.sentry.io/5504279",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
  });
}
// prod: https://workflowmanager.co.za/graphql
//  local: http://localhost:4000/graphql
const httpLink = createHttpLink({
  uri: "https://workflowmanager.co.za/graphql",
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getAuthHeader = async () => {
  if (firebase.auth().currentUser) {
    const authToken = await firebase.auth().currentUser?.getIdToken(false);
    return `${authToken}`;
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authenticationLink = setContext(async (_, { headers }) => {
  const token = await getAuthHeader();
  if (token) {
    return {
      headers: {
        ...headers,
        authorisation: token,
        apiKey: "7091108b123556517d060df66ad199ef",
      },
    };
  }
  return {
    headers: {
      authorisation: "jsbclabs",
      apiKey: "7091108b123556517d060df66ad199ef",
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authenticationLink, httpLink]),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

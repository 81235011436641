import dayjs from "dayjs";
import React, { useEffect } from "react";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import TimeSlot from "./TimeSlot";

interface TimeSlotPickerProps {
  selectedTime?: { hour: number | undefined; minute: number | undefined };
  onChangeTime: (
    selectedTime: { hour: number; minute: number },
    doctors: any,
  ) => void;
  possibleTimes: { startTime: Date; doctors: [] }[] | undefined;
}

const TimeSlotPicker = ({
  selectedTime,
  onChangeTime,

  possibleTimes,
}: TimeSlotPickerProps) => {
  const handleSetSelectedTimeSlot = (
    hour: number,
    minute: number,
    doctors: any,
  ) => {
    if (hour !== undefined && minute !== undefined) {
      onChangeTime({ hour, minute }, doctors);
    }
  };
  dayjs.extend(utc);
  dayjs.extend(timezone);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const generateAllTimeSlots = () => {
    const allTimeSlots: JSX.Element[] = [];
    if (possibleTimes === undefined) {
      return undefined;
    }

    possibleTimes.forEach((possibleTime) => {
      allTimeSlots.push(
        <TimeSlot
          key={possibleTime.startTime.toTimeString()}
          hour={dayjs(possibleTime.startTime).tz("Africa/Johannesburg").hour()}
          minute={dayjs(possibleTime.startTime)
            .tz("Africa/Johannesburg")
            .minute()}
          selected={
            selectedTime?.hour ===
              dayjs(possibleTime.startTime).tz("Africa/Johannesburg").hour() &&
            selectedTime.minute ===
              dayjs(possibleTime.startTime).tz("Africa/Johannesburg").minute()
          }
          doctors={possibleTime.doctors}
          onClick={handleSetSelectedTimeSlot}
        />,
      );
    });

    return allTimeSlots;
  };
  const times = generateAllTimeSlots();
  if (times === undefined || times.length === 0) {
    return (
      <div className="time-slot-picker">
        <div className="time-list">No times available</div>
      </div>
    );
  }
  return (
    <div className="time-slot-picker">
      <div className="title">Available times</div>
      <div className="time-list">
        <div>{times.map((time) => time)}</div>
      </div>
    </div>
  );
};

export default TimeSlotPicker;

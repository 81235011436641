import React from "react";
import { FaUser } from "react-icons/fa";

interface DoctorProps {
  title: string;
  id: number;
  imageUrl?: string;
  onClick: (id: number) => void;
  selected: boolean;
  disabled?: boolean;
  subTitle?: string;
}

const Doctor = ({
  title,
  imageUrl,
  id,
  onClick,
  selected = false,
  disabled = false,
  subTitle,
}: DoctorProps) => (
  <button
    className={`doctor${selected === true ? " selected" : ""} ${
      disabled ? " disabled" : ""
    }`}
    type="button"
    disabled={disabled}
    onClick={() => onClick(id)}
  >
    <div className="image-wrapper">
      {imageUrl ? <img src={imageUrl} alt="doctor" /> : <FaUser size="16px" />}
    </div>

    <span className="doctor-name">{title}</span>
    {subTitle && (
      <span className="sub-title u-margin-top-small">{subTitle}</span>
    )}
  </button>
);

export default Doctor;

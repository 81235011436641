import React from "react";
import NewsletterBlock from "../landing/NewsletterBlock";
// import Footer from "./Footer";
import Header from "./Header";

interface LayoutProps {
  children: React.ReactNode;
  showNewsletterSignup?: boolean;
}

const Layout = ({ children, showNewsletterSignup }: LayoutProps) => {
  return (
    <div className="layout">
      <Header />
      <main className={`${showNewsletterSignup && "small-padding-bottom"}`}>
        {" "}
        {children}
      </main>
      {showNewsletterSignup && <NewsletterBlock />}
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;

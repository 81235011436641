import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";

import { toast } from "react-toastify";
import { isEmail } from "../../../../helpers/regexHelper";
import useScript from "../../../../hooks/useScript";
import fetchUserByEmail from "../../../../queries/fetchUserByEmail";
import { IPatientDetails } from "../../../../types";
import AddressLookup from "../../../shared/AddressLookup";
import StandardInput from "../../../shared/StandardInput";
import StandardRadioSelect from "../../../shared/StandardRadioSelect";

declare global {
  interface Window {
    YocoSDK: any;
  }
}
interface CapturePatientDetailsProps {
  allowMedicalAidToggle?: boolean;
  userDetails: IPatientDetails;
  onFormSubmit: (patientDetaisl: IPatientDetails, token?: string) => void;
  requireConsultationAddress?: boolean;
  appointmentCost: number;
}

const CapturePatientDetails = ({
  allowMedicalAidToggle = true,
  userDetails,
  onFormSubmit,
  requireConsultationAddress = false,
  appointmentCost,
}: CapturePatientDetailsProps) => {
  const [showMedicalAid, setShowMedicalAid] = useState(false);
  const existingNewsletterCookie = Cookies.get("viewedNewsletterSignup");
  const [showNewsletterSignup, setShowNewsletterSignup] = useState(
    !existingNewsletterCookie,
  );
  const [fetchUserByEmailAddress, { data: userEmailData }] = useLazyQuery(
    fetchUserByEmail,
  );

  const { register, errors, handleSubmit, watch } = useForm({
    defaultValues: Object.assign(userDetails),
  });
  const email = watch("emailAddress"); // you can also target specific fields by their names

  useScript(`https://js.yoco.com/sdk/v1/yoco-sdk-web.js`, true);
  const options = [
    {
      name: "Yes",
      value: true,
    },

    {
      name: "No",
      value: false,
    },
  ];

  const showYocoPopup = (data: any) => {
    const yoco = new window.YocoSDK({
      publicKey: `${process.env.REACT_APP_YOCO_PUBLIC_KEY}`,
    });
    yoco.showPopup({
      amountInCents: appointmentCost * 100,
      currency: "ZAR",
      name: "Prime Medical Solutions",
      description: "Appointment",
      callback(result: any) {
        if (result.error) {
          const errorMessage = result.error.message;
          toast.error(`Error: ${errorMessage}`);
        } else {
          onFormSubmit(data, result.id);
        }
      },
    });
  };

  const handleOnChange = (selectedOption: any) => {
    setShowMedicalAid(selectedOption);
  };

  const onSubmit = async (data: IPatientDetails) => {
    let formData;

    if (!showNewsletterSignup) {
      formData = { ...data, newsletter: false };
    } else {
      formData = data;
    }
    if (formData.medicalAidName || formData.medicalAidNumber) {
      onFormSubmit(formData);
    } else {
      showYocoPopup(formData);
    }
  };
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (isEmail(email) && !existingNewsletterCookie) {
        fetchUserByEmailAddress({ variables: { email } });
      }
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [email]);

  useEffect(() => {
    if (!existingNewsletterCookie) {
      if (userEmailData && userEmailData.userByEmail) {
        setShowNewsletterSignup(false);
      } else {
        setShowNewsletterSignup(true);
      }
    }
  }, [userEmailData]);

  return (
    <div className="capture-patient-details">
      <h1 className="u-margin-bottom-small">Patient Details</h1>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {allowMedicalAidToggle && (
          <>
            <h2 className="medical-aid-question u-margin-bottom-small">
              Are you using medical aid?
            </h2>

            <StandardRadioSelect
              options={options}
              onChange={handleOnChange}
              selected={showMedicalAid}
            />
          </>
        )}
        {requireConsultationAddress && (
          <>
            <AddressLookup>
              <StandardInput
                autoComplete="off"
                inputRef={register({ required: true })}
                name="appointmentLocation"
                variant="dark"
                key="appointmentLocation"
                type="text"
                label="Consultation Address"
                error={errors.consultationAddress as any}
                errorMessage="A consultation address is required for home consultations"
              />
            </AddressLookup>
          </>
        )}
        <StandardInput
          inputRef={register({ required: true })}
          name="firstName"
          variant="dark"
          label="First name"
          error={errors.firstName as any}
          errorMessage="First name required"
          autoComplete="name"
        />

        <StandardInput
          variant="dark"
          label="Last name"
          name="lastName"
          error={errors.lastName as any}
          errorMessage="Last name required"
          inputRef={register({ required: true })}
        />
        <StandardInput
          variant="dark"
          label="ID Number"
          inputRef={register({
            required: true,
            pattern: /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/,
          })}
          error={errors.idNumber}
          errorMessage="ID Number required"
          name="idNumber"
        />

        <StandardInput
          variant="dark"
          name="cellNumber"
          label="Cell number"
          error={errors.cellNumber}
          errorMessage="Cell number required"
          inputRef={register({ required: true })}
        />

        <StandardInput
          variant="dark"
          label="Email address"
          name="emailAddress"
          autoComplete="email"
          errorMessage="Email address required"
          error={errors.emailAddress}
          inputRef={register({
            required: true,
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
          })}
        />

        {showMedicalAid ? (
          <>
            <AddressLookup>
              <StandardInput
                error={errors.physicalAddress}
                errorMessage="Physical address required"
                variant="dark"
                name="physicalAddress"
                label="Physical address"
                inputRef={register({
                  required: true,
                })}
              />
            </AddressLookup>

            <StandardInput
              variant="dark"
              label="Medical Aid name"
              name="medicalAidName"
              error={errors.medicalAidName}
              inputRef={register({
                required: true,
              })}
              errorMessage="Medical Aid name required"
            />
            <StandardInput
              variant="dark"
              label="Medical Aid plan"
              name="medicalAidPlan"
              error={errors.medicalAidPlan}
              inputRef={register({
                required: true,
              })}
              errorMessage="Medical Aid Plan required"
            />
            <StandardInput
              variant="dark"
              label="Medical Aid number"
              name="medicalAidNumber"
              error={errors.medicalAidNumber}
              inputRef={register({
                required: true,
              })}
              errorMessage="Medical Aid Number required"
            />
          </>
        ) : (
          <AddressLookup>
            <StandardInput
              variant="dark"
              label="Billing address"
              error={errors.billingAddress}
              errorMessage="Billing address required"
              name="billingAddress"
              inputRef={register({
                required: true,
              })}
            />
          </AddressLookup>
        )}
        {showNewsletterSignup && (
          <div className="newsletter-signup">
            <label htmlFor="newletter">
              <input
                name="newsletter"
                id="newsletter"
                type="checkbox"
                defaultChecked
                ref={register()}
              />{" "}
              Sign me up to the Prime Medical Inc newsletter
            </label>
          </div>
        )}

        {!showMedicalAid ? (
          <button
            className="submit-form-button u-margin-top-small"
            type="submit"
          >
            Pay (R{appointmentCost.toFixed(2)}) and create appointment
          </button>
        ) : (
          <button
            className="submit-form-button u-margin-top-small"
            type="submit"
          >
            Create appointment
          </button>
        )}
        <span className="policy-agreement">
          By continuing, you agree to our Terms and Data Policy.
        </span>
      </form>
    </div>
  );
};

export default CapturePatientDetails;

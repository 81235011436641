import FullCalendar from "@fullcalendar/react";
import React from "react";
import dayGridPlugin from "@fullcalendar/daygrid";

import dayjs from "dayjs";
import { IAppointment } from "../../../../../types";

interface PatientAppointmentsProps {
  appointments: IAppointment[];
}
const PatientAppointments = ({ appointments }: PatientAppointmentsProps) => {
  if (appointments.length === 0) {
    return <h2>No upcoming appointments</h2>;
  }

  const appointmentEvents = appointments.map((appointment) => {
    return {
      start: dayjs(appointment.start_date).toDate(),
      end: dayjs(appointment.end_date).toDate(),
    };
  });

  return (
    <div className="patient-appointments">
      <span className="page-heading">Appointments</span>

      <FullCalendar
        handleWindowResize
        headerToolbar={{
          right: "",
          center: "title",
          left: "prev,next",
        }}
        events={appointmentEvents}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
      />
    </div>
  );
};

export default PatientAppointments;

import { gql } from "@apollo/client";

export default gql`
  query fetchAvailableDoctors($appointmentTime: String!) {
    availableDoctors(appointmentTime: $appointmentTime) {
      id
      firstName
      lastName
      emailAddress
      mobileNumber
    }
  }
`;
